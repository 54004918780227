<ng-container *ngIf="label">
    <p class="font-weight-bold ml-4">{{ label }}</p>
</ng-container>
<ul class="recipients-list pl-4">
    <li *ngFor="let recipient of recipients" [ngClass]="{ active: recipient.email === selected?.email || showOpen }"
        class="p-3 border border-primary primary rounded">
      <div (click)="toggle(recipient) && !recipient.privacyProtected" [tooltip]="recipient.privacyProtected ? '*****' : recipient.email" class="recipient-header">
        {{ recipient.name }}
      </div>
      <div class="recipient-info show">
        <dl class="row">
            <ng-container *ngIf="recipient.signatoryRole !== 'CC'">
              <dt class="col-5">{{ 'signrequest.signatory_role' | translate}}</dt>
              <dd class="col-7">{{  'roles.role_' + recipient.signatoryRole.toLowerCase() | translate }}</dd>
            </ng-container>

          <ng-container *ngIf="!recipient.privacyProtected">
            <dt class="col-5">{{ 'signrequest.email_address' | translate }}</dt>
            <dd class="col-7">{{ recipient.email }}</dd>
            <dt class="col-5">{{ 'contacts.phone' | translate }}</dt>
            <dd class="col-7">{{ recipient.phone ? recipient.phone : 'N/A' }}</dd>
          </ng-container>

          <ng-container *ngIf="getAuthMethodsByType(recipient, 'PREVIEW').length && getAuthMethodsByType(recipient, 'PREVIEW') as AM">
            <dt class="col-5">{{ 'workflow.authentication_preview' | translate }}</dt>
            <dd class="col-7">
              <span *ngFor="let am of AM" class="badge badge-primary rounded-border">{{ 'signrequest.am.' + am.type | translate }}</span>
            </dd>
          </ng-container>
           <ng-container *ngIf="getAuthMethodsByType(recipient, 'AFTERVIEW').length && getAuthMethodsByType(recipient, 'AFTERVIEW') as AM">
            <dt class="col-5">{{ 'workflow.authentication' | translate }}</dt>
            <dd class="col-7">
              <span *ngFor="let am of AM" class="badge badge-primary rounded-border">{{ 'signrequest.am.' + am.type | translate }}</span>
            </dd>
          </ng-container>

            <dt class="col-5">{{ 'signrequest.language' | translate }}</dt>
            <dd class="col-7">{{ 'locale.' + recipient.locale | translate }}</dd>
        </dl>

          <ng-container *ngIf="recipient.signatoryRole === 'SIGN' || recipient.signatoryRole === 'ACCEPTANCE'">

              <ng-container *ngIf="canChange; else showStatic">
                    <div>
                      <div class="checkbox c-checkbox pr-3">
                        <label>
                          <input (change)="toggleDisableEmail(recipient)"
                                 [checked]="!recipient.disableEmail"
                                 type="checkbox">
                          <span class="fa fa-check"></span> {{ 'signrequest.receive_document' | translate }}
                        </label>
                      </div>
                    </div>
                  <div>
                  <div class="checkbox c-checkbox pr-3">
                    <label>
                      <input (change)="toggleDisableInvitation(recipient)"
                             [checked]="!recipient.disableInvitation"
                             type="checkbox">
                      <span class="fa fa-check"></span> {{ 'signrequest.receive_invitation' | translate }}
                    </label>
                  </div>
                </div>
              </ng-container>

              <ng-template #showStatic>
                <div>
                  <div>
                     <em [ngClass]="!recipient.disableEmail ? 'fa-check-square text-success' : 'fa-square text-muted'" class="fas fa-fw fa-lg"></em> {{ 'signrequest.receive_document' | translate }}
                  </div>
                  <div>
                    <em [ngClass]="!recipient.disableInvitation ? 'fa-check-square text-success' : 'fa-square text-muted'" class="fas fa-fw fa-lg"></em> {{ 'signrequest.receive_invitation' | translate }}
                  </div>
                </div>
              </ng-template>
          </ng-container>
      </div>
    </li>
</ul>
