import { Component } from '@angular/core';
import { InfoModal } from './info.modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent {
    get infoModal(): InfoModal {
        return this._infoModal;
    }

    set infoModal(value: InfoModal) {
        this._infoModal = value;
    }

    private _infoModal!: InfoModal;

    constructor(
        public bsModalRef: BsModalRef
    ) {
    }
}
