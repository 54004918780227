import {
    Component,
    EventEmitter,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { DataAction, DataRowAction, DataRowColumn } from '../../models/datarow';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { TranslateService } from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-data-list',
    templateUrl: './data-list.component.html',
    styleUrls: ['./data-list.component.scss']
})
export class DataListComponent implements OnInit, OnChanges, OnDestroy {
    @Input() data!: any[];
    @Input() columns: DataRowColumn[] = [];
    @Input() actions: DataRowAction[] = [];
    @Input() rowLimit = 10;
    @Input() entityTitle = 'datatables.default_label';
    @Input() isLoading = false;
    @Input() detailTemplate?: TemplateRef<any>;
    @Input() noResultsMessage = 'datatables.no_result';
    @Output() action: EventEmitter<DataAction> = new EventEmitter<DataAction>();
    @ViewChild('dataTable', {static: true}) table: any;

    readonly language: string;
    readonly columnMode = ColumnMode;
    messages = {emptyMessage: '', totalMessage: ''};
    expanded: any = {};
    isExpandable = false;
    selected = [];
    showMasked!: number | null;
    subscription: Subscription = new Subscription();
    private activeRow!: unknown;

    constructor(
        private translate: TranslateService
    ) {
        this.language = this.translate.currentLang;

        this.subscription.add(this.translate.onLangChange.subscribe(() => this.setMessage()));
    }

    ngOnInit() {
        this.setMessage();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['entityTitle']?.previousValue !== changes['entityTitle']?.currentValue) {
            this.setMessage();
        }

        if (this.detailTemplate) {
            this.isExpandable = true;
        }
    }

    getRowClass(row: any) {
        return {
            'active': row['active']
        };
    }

    toggleExpandRow(row: any) {
        console.log('row', row);
        if (row !== this.activeRow) {
            this.table.rowDetail.collapseAllRows();
        }
        this.activeRow = row;
        this.table.rowDetail.toggleExpandRow(row);
    }

    emitAction(event: Event, type: string, data: any) {
        event.preventDefault();
        event.stopPropagation();

        this.action.emit({type: type, data: data});
    }

    toggleMasked(rowIndex: number) {
        this.showMasked = this.showMasked !== rowIndex ? rowIndex : null;
    }

    setMessage() {
        this.messages.emptyMessage = `<span class="text-bold">${this.translate.instant(this.noResultsMessage)}</span><br>`;
        if (this.entityTitle) {
            this.messages.totalMessage = this.translate.instant(this.entityTitle);
        }
    }
}
