import {DocumentTemplateLabel} from '@shared/models/documenttemplate-label/document.template.label';

export class DocumentTemplate {
    id!: number;
    name!: string;
    filename!: string;
    description!: string;
    documentTemplateUUID!: string;
    maxSignatories!: number;
    content?: string;
    templateLabels?: DocumentTemplateLabel[];
}
