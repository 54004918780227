import { Component, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OnboardingModal } from '../../models/onboarding-modal';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserService } from '@services/user/user.service';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap/carousel/carousel';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {
    displayNav = true;
    @ViewChild('ngbCarousel') ngbCarousel!: NgbCarousel;

    constructor(
        private router: Router,
        public bsModalRef: BsModalRef,
        private userService: UserService
    ) {
    }

    private _onboardingModals!: OnboardingModal[];

    get onboardingModals(): OnboardingModal[] {
        return this._onboardingModals;
    }

    set onboardingModals(value: OnboardingModal[]) {
        this._onboardingModals = value;
    }

    private _routeLink!: string;

    get routeLink(): string {
        return this._routeLink;
    }

    set routeLink(value: string) {
        this._routeLink = value;
    }

    skip() {
        this.bsModalRef.hide();
        this.router.navigate([this.routeLink]);
        this.userService.updateOnboarding();
        this.displayNav = true;
    }

    onSlide(slideEvent: NgbSlideEvent) {
        console.log('se: ' + slideEvent.current);
        const slideId: number = Number(slideEvent.current);
        const index: number = this._onboardingModals.map(value => value.id).indexOf(slideId);
        this.displayNav = index + 1 !== this._onboardingModals.length;
    }

    routeToLink() {
        this.skip();
    }
}
