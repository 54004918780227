import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormInputOptions } from '@shared/models/form-input';
import { Locale } from '@shared/models/signrequest/sign.signatory';

export interface Language {
    code: Locale;
    text: string;
}

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {
    private readonly defaultLanguage: Language = {code: Locale.NL, text: 'Nederlands'};

    constructor(
        private readonly translate: TranslateService
    ) {
        if (!translate.getDefaultLang()) {
            translate.setDefaultLang(this.defaultLanguage.code);
        }

        this.setLanguage(this.defaultLanguage).then((res) => this.currentLanguage = res);
    }

    private _currentLanguage!: Language;

    get currentLanguage(): Language {
        return this._currentLanguage;
    }

    set currentLanguage(value: Language) {
        this._currentLanguage = value;
        this.translate.use(value.code);
    }

    private _availableLanguages: Language[] = [
        {code: Locale.NL, text: 'Nederlands'},
        {code: Locale.DE, text: 'Duits'},
        {code: Locale.EN, text: 'English'},
        {code: Locale.FR, text: 'Français'},
        {code: Locale.ES, text: 'Español'}
    ];

    get availableLanguages(): Language[] {
        return this._availableLanguages;
    }

    get availableLanguagesOptions(): FormInputOptions[] {
        return this._availableLanguages.map(language => ({ value: language.code, label: language.text }));
    }

    setLanguage(language: Language): Promise<Language> {
        return this.translate.use(language.code || this.translate.getDefaultLang())
            .toPromise()
            .then(() => language);
    }
}
