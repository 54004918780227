import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { AuthorizationService } from '@services/authorization/authorization.service';

@Injectable({
    providedIn: 'root'
})
export class RouteAccessGuard  {
    constructor(
        private readonly service: AuthorizationService
    ) {}

    canActivateChild(childRoute: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        return this.hasAccess(childRoute);
    }

    private hasAccess(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            console.log('route data', route.url, route.data);
            const module = route.data['module'];
            /**
             * Minimal permission level is READ unless it is defined on the route
             */
            const permission = route.data['permission'] || 'READ';
            if (!module) {
                return resolve(true);
            }

            if (module === 'SYSOPS') {
                return resolve(this.service.isSysOp);
            }

            console.log('hasAccess', module, permission);

            resolve(this.service.hasComponentAccess(module, permission));
        });
    }
}
