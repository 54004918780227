import { KeycloakService } from 'keycloak-angular';
import { TranslatorService } from '@services/translator/translator.service';
import { Locale } from '@shared/models/signrequest/sign.signatory';
import { ConfigService } from '@services/config/config.service';
import { mergeMap } from 'rxjs/operators';
import {ErrorDisplayService} from '@services/error-display/error-display.service';

function getAccountName(location: Location): string {

    const pathnames = location.pathname.split('/').filter(path => path);

   if ( ( location.hostname === 'localhost'
           || location.hostname === 'testzyn'
           || location.hostname === 'testzyn.zoetermeer.nl'
           || location.hostname === 'ztmzyn.zoetermeer.nl'
        || location.hostname === 'start.zynyo.com')
        && pathnames.length) {
        // Returns first part after host start.zynyo.com/account-name
        console.log(pathnames[0]);
        return pathnames[0];
    } else {
        // Returns first part of the host account-name.zynyo.com
        const subdomain = location.hostname.split('.dtm.zynyo.com').find(path => path);

        return subdomain ?? 'kubernetes';

    }
}

export function initializer(
    keycloak: KeycloakService,
    translatorService: TranslatorService,
    configService: ConfigService,
    errorDisplayService: ErrorDisplayService,
    location: Location
): () => Promise<any> {
    /** Get accountname from location
     * start.zynyo.com/account-name
     * account-name.zynyo.com
     * digihandtekening.haarlem.nl
     */
    const account = getAccountName(location);
    console.log(`Found account '${account}'`);

    return (): Promise<any> => configService.fetchConfig(account)
        .pipe(
            mergeMap((res) => {
                return keycloak.init({
                    config: res.keycloakConfig,
                    initOptions: {
                        onLoad: 'login-required',
                        checkLoginIframe: false
                    },
                    enableBearerInterceptor: true,
                    bearerExcludedUrls: ['/assets', '/clients/public']
                });
            })
        )
        .toPromise()
        .then(() => translatorService.setLanguage({code: Locale.NL, text: 'Nederland'}))
        .catch(error => errorDisplayService.displayError('De server is niet beschikbaar', error.message));
}
