<div class="modal-header">
  <h4 class="modal-title float-left">Verstuurde emails naar <span *ngIf="signatory">{{ signatory.name }}</span></h4>
</div>

<div class="modal-body" *ngIf="signatory">
  <div class="emails">
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">Datum</th>
          <th scope="col">Onderwerp</th>
          <th scope="col">Ontvanger</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let emailStateReport of signatory.emailStateReports">
          <td>{{ emailStateReport.added | amFromUtc | amLocale:'nl' | amLocal | amDateFormat: 'LLL' }} </td>
          <td>{{ emailStateReport.subject }}</td>
          <td>{{ emailStateReport.toAddress }}</td>
          <td [class]="stateClass(emailStateReport.state)"><strong>{{ 'emailstate.' + emailStateReport.state | translate }}</strong></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="">
    <button class="btn btn-outline-danger" (click)="bsModalRef.hide()">Sluiten</button>
  </div>

</div>
