import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
    @ViewChild('fileInput', { static: true }) fileInput!: ElementRef;
    @Input() form!: UntypedFormGroup;
    @Input() control!: string;
    @Input() removeLabel = 'file_upload.remove_image';
    @Input() maxSize = 2097152; // Max size in bytes ( 2048 Kb )
    allowedTypes: string[] = ['image/png', 'image/jpeg']; // Use a MIME type

    fileError: string | null = null;
    fileName = '';
    @Input() fileType = '';
    readonly maxHeight = 250;
    readonly maxWidth = 250;

    constructor(
        private readonly translate: TranslateService,
    ) {
    }

    get FormControl() {
        return this.form.get(this.control);
    }

    fileChangeEvent(fileInput: any) {
        this.fileError = null;
        const file: File = fileInput.target.files[0];
        if (file) {
            if (file.size > this.maxSize) {
                this.fileError = this.translate.instant(
                    'file_upload.max_filesize',
                    {filesize: this.maxSize / 1000}
                );
                return;
            }

            if (!this.allowedTypes.includes(file.type)) {
                const filetypes: string[] = [];
                for (const filetype of this.allowedTypes) {
                    filetypes.push(filetype.toUpperCase().split('/')[1]);
                }
                this.fileError = this.translate.instant(
                    'file_upload.types_allowed',
                    {filetypes: filetypes}
                );
                return;
            }

            const reader = new FileReader();
            reader.onload = (e: any) => {
                if (file.type === 'image/png' || file.type === 'image/jpeg') {
                    this.processImage(file, e);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    processImage(file: File, e: any) {
        this.fileType = file.type;
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
            // @ts-ignore
            const imgHeight = rs.currentTarget['height'];
            // @ts-ignore
            const imgWidth = rs.currentTarget['width'];

            if (imgHeight > this.maxHeight || imgWidth > this.maxWidth) {
                this.fileError = this.translate.instant(
                    'file_upload.max_dimensions',
                    {height: this.maxHeight, width: this.maxWidth}
                );
                return;
            } else {
                const imgBase64Path = e.target.result;
                this.FormControl?.patchValue(imgBase64Path);
            }
        };
    }

    removeFile() {
        this.fileInput.nativeElement.value = '';
        this.FormControl?.patchValue('');
        this.fileName = '';
        this.fileType = '';
    }
}
