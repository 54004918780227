import { Pipe, PipeTransform } from '@angular/core';
import { EnabledAuthMethod } from '../../models/settings/enabled-auth-method';
import { AuthMethod } from '../../models/settings/auth-method';

@Pipe({
    name: 'sortAuthMethods'
})
export class SortAuthMethodsPipe implements PipeTransform {

    transform(authMethods: AuthMethod[] | EnabledAuthMethod[]): AuthMethod[] | EnabledAuthMethod[] {
        if ( !authMethods || !authMethods.length) {
            return [];
        }

        const sorting = ['mouse', 'mouse_preview', 'smstan', 'smstan_preview', 'sso', 'sso_preview', 'idin', 'idin_preview', 'irma', 'irma_preview', 'digid', 'digid_preview', 'checkedid', 'csc', 'documenttemplate', 'document_anchors', 'certificate'];
        let sorted;

        if (typeof authMethods[0] === 'string') {
            sorted = (authMethods as AuthMethod[]).sort((a, b) => {
                return sorting.indexOf(a) > sorting.indexOf(b) ? 1 : -1;
            });
        } else {
            sorted = (authMethods as EnabledAuthMethod[]).sort((a, b) => {
                return sorting.indexOf(a.authenticationMethod) > sorting.indexOf(b.authenticationMethod) ? 1 : -1;
            });
            // sorted = (authMethods as string[]).sort((a, b) => {
            //     return sorting.indexOf(a) > sorting.indexOf(b) ? 1 : -1;
            // });
        }

        return sorted;
    }

}
