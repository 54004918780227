<ng-container *ngIf="'select' === field.type; else ShowError" [formGroup]="form">
    <ng-container *ngTemplateOutlet="InputTemplate" [formGroupName]="field.group ? field.group : null" ></ng-container>
</ng-container>

<ng-template #InputTemplate>
    <div class="form-group mb-3" [ngClass]="{'d-flex align-items-center': inlineLabel }">
        <label [for]="field.control" class="text-bold">{{ field.label | translate }}</label>

        <select class="form-control"
                [id]="field.control"
                [formControl]="Control"
                (ngModelChange)="selectOption($event)">
            <option [ngValue]="null" *ngIf="!isRequired">{{ 'form.select_option' | translate }}</option>
            <ng-container *ngFor="let option of field.options">
              <option [value]="option.value">{{ option.label | translate }}</option>
            </ng-container>
        </select>
    </div>
</ng-template>

<ng-template #ShowError>
  <div class="form-group mb-3">
    {{ field.control }} verkeerde input type: {{ field.type }} ipv select
  </div>
</ng-template>
