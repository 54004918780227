<div>
    <input class="form-control"
           type="text"
           [formControl]="inputControl"
           [placeholder]="placeholder"
           [class.ng-invalid]="invalid || inputControl.invalid">
</div>

<div *ngIf="inputControl.invalid && inputControl.touched" class="pt-2 text-danger">
  <p *ngIf="inputControl.hasError('pattern') || inputControl.hasError('email')" [innerHTML]="'form.invalidEmail' | translate"></p>
</div>
