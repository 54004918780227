import {AuthenticationMethod} from '@shared/models/signrequest/authentication/authentication-method';

export class SignSignatory {
    name!: string;
    email!: string;
    phone!: string;
    locale: Locale = Locale.NL;
    authenticationMethods: AuthenticationMethod[] = [];
    priority: Priority = Priority.DEFAULT;
    signatoryRole: Role = Role.SIGN;
    recipientRole!: Role;
    available = true;
    disableEmail = false;
    disableInvitation = false;
    disableStatusChange = true;
    privacyProtected = false;
    language = 'nl';
    roles!: string[] | null;
}

export enum Locale {
    NL = 'nl',
    EN = 'en',
    DE = 'de',
    FR = 'fr',
    ES = 'es'
}

export enum Priority {
    DEFAULT = 'DEFAULT'
}

export enum Role {
    SIGN = 'SIGN',
    CC = 'CC',
    ACCEPTANCE = 'ACCEPTANCE',
    DELIVERY = 'DELIVERY'
}

export type RoleType = 'SIGN' | 'CC' | 'ACCEPTANCE' | 'DELIVERY';
