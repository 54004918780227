import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '@services/config/config.service';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    readonly url = this.config.config.apiBaseUrl;
    private _apiKey = '';

    constructor(
        @Inject('resource') private readonly resource: string | null,
        protected readonly http: HttpClient,
        protected readonly config: ConfigService,
        @Inject('apiKeyHeader') private readonly apikeyEnabled = true
    ) {
        if (this.resource) {
            this.url = `${this.url}${this.resource}`;
        }
    }

    get ApiKey() {
        return this._apiKey;
    }

    set ApiKey(apiKey) {
        this._apiKey = apiKey;
    }

    private static createHeaders(apiKey = '', apiKeyEnabled: boolean) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        if (apiKeyEnabled && apiKey) {
            headers = headers.append('apikey', apiKey);
        }
        return headers;
    }

    getAll<T>(resource = '', params: { [param: string]: string | boolean } | null = null): Observable<T> {
        const headers = DataService.createHeaders(this.ApiKey, this.apikeyEnabled);
        let url = this.url;
        if (resource) {
            url = `${url}${resource}`;
        }
        // @ts-ignore
        return this.http.get<T>(url, {headers: headers, params: params});
    }

    get<T>(id: string, params: { [param: string]: string | boolean } | null = null): Observable<T> {
        const headers = DataService.createHeaders(this.ApiKey, this.apikeyEnabled);

        let url = `${this.url}/${id}`;
        if (params) {
            url = `${url}/${params}`;
        }
        return this.http.get<T>(url, {headers: headers});
    }

    create<T>(request: any, resource?: string): Observable<T> {
        const headers = DataService.createHeaders(this.ApiKey, this.apikeyEnabled);

        let url = this.url;
        if (resource) {
            url = `${url}/${resource}`;
        }
        return this.http.post<T>(url, request, {headers: headers});
    }

    update<T>(request: any, resource?: string, identifier = 'id'): Observable<T> {
        const headers = DataService.createHeaders(this.ApiKey, this.apikeyEnabled);
        let url = this.url;
        if (request[identifier]) {
            url = `${url}/${request[identifier]}`;
        }
        if (resource) {
            url = `${url}/${resource}`;
        }
        return this.http.put<T>(url, request, {headers: headers});
    }

    delete(id: string) {
        const headers = DataService.createHeaders(this.ApiKey, this.apikeyEnabled);
        return this.http.delete(`${this.url}/${id}`, {headers: headers});
    }
}
