import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import {Subscription} from 'rxjs';

// tslint:disable-next-line:max-line-length
const EmailPattern = '(\\S*(([A-Za-z\\S]|[0-9])|([-]|[_]|[.][\\S]))*\\@(([A-Za-z0-9])|([-])){2,63}(\\.\\w{2,63})(\\.\\w{2,63})?)';

@Component({
    selector: 'app-email-input',
    templateUrl: './email-input.component.html',
    styles: []
})
export class EmailInputComponent implements OnInit, OnChanges, OnDestroy {
    @Input() placeholder?: string;
    @Input() invalid?: boolean;
    @Input() value?: string;
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() errors: EventEmitter<ValidationErrors | null> = new EventEmitter<ValidationErrors | null>();

    readonly inputControl = new UntypedFormControl(null, [Validators.email, Validators.pattern(EmailPattern)]);
    private subscription: Subscription = new Subscription();

    ngOnInit() {
        if (this.value) {
            this.inputControl.patchValue(this.value);
        }

        this.subscription.add(this.inputControl.valueChanges
            .pipe(
                distinctUntilChanged()
            )
            .subscribe(res => {
                this.valueChange.emit(res);
                this.errors.emit(this.inputControl.errors);
            }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['value'].previousValue !== changes['value'].currentValue) {
            this.inputControl.patchValue(this.value);
        }
    }

}
