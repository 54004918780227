<ng-container *ngIf="'select' === field.type; else ShowError" [formGroup]="form">
    <ng-container *ngTemplateOutlet="InputTemplate" [formGroupName]="field.group ? field.group : null" ></ng-container>
</ng-container>

<ng-template #InputTemplate>
    <div class="form-group mb-3">
        <label [for]="field.control" class="text-bold">{{ field.label | translate }}</label>

        <div class="btn-group w-100" dropdown>
            <ng-container *ngIf="!selectedOptions.length; else showSelectedOptions">
                <div class="form-control d-flex align-items-center dropdown-toggle" dropdownToggle>
                    <span class="w-100" [innerHTML]="'form.no_option_selected' | translate: { label: (field.label | translate) } | safeHTML "></span>
                </div>
            </ng-container>

            <ng-template #showSelectedOptions>
                <div class="form-control h-auto p-1 d-flex align-items-center dropdown-toggle" dropdownToggle>
                    <div class="w-100">
                        <ng-container *ngFor="let option of selectedOptions, let i = index">
                            <div class="btn-group m-1">
                                <button class="btn btn-xs btn-danger px-2" (click)="removeSelected(i)">x</button>
                                <div class="btn btn-xs btn-primary">{{ option.label | translate }}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-template>

            <div class="dropdown-menu w-100" role="menu" *dropdownMenu>
                <ng-container *ngFor="let option of options">
                    <button class="dropdown-item"
                            type="button"
                            (click)="addSelected(option)"
                            [ngClass]="{ 'text-muted disabled': isSelected(option.value)}">
                        {{ option.label | translate }}
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #ShowError>
  <div class="form-group mb-3">
    {{ field.control }} verkeerde input type: {{ field.type }} ipv select
  </div>
</ng-template>
