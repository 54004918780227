import { Injectable } from '@angular/core';
import { ContactsService } from '../contacts/contacts.service';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';
import { Profile } from '@shared/models/user';
import { ConfigService } from '@services/config/config.service';
import {ErrorDisplayService} from '@services/error-display/error-display.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private onboarded = false;

    constructor(
        private readonly keycloakService: KeycloakService,
        private readonly contactsService: ContactsService,
        private readonly http: HttpClient,
        private readonly configService: ConfigService,
        private readonly errorDisplayService: ErrorDisplayService,
        private readonly translate: TranslateService
    ) {
    }

    private _department!: string | null;

    get department(): string | null {
        return this._department;
    }

    set department(value: string | null) {
        this._department = value;
    }

    private _userProfile!: Profile;

    get userProfile(): Profile {
        return this._userProfile;
    }

    set userProfile(value: Profile) {
        this._userProfile = value;
    }

    getUserIsOnboarded(): string | null {
        return this.userProfile?.attributes?.hasOwnProperty('onboarded') ? this.userProfile?.attributes?.['onboarded'][0] : null;
    }

    updateOnboarding() {
        this.http.put(`${this.configService.config.springAppUrl}/user/update_onboarding`, null)
            .subscribe((value: any) => {
                if (value?.message === 'success') {
                    this.onboarded = true;
                }
            });
    }

    loadUserProfile(): Promise<Profile> {
        return this.keycloakService
            .loadUserProfile()
            .then((keycloakProfile) => {
                const profile = keycloakProfile as Profile;
                this.userProfile = profile;
                this.findDepartment();
                return profile;
            }, (error: any) => {
                this.errorDisplayService.displayError(this.translate.instant('error.server_unavailable.title'), error.message);
                return error;
            });
    }

    private findDepartment() {
        const department = this.userProfile?.attributes.hasOwnProperty('department') ? this.userProfile?.attributes.department[0] : null;
        if (department === null && this.userProfile.email || !department && this.userProfile.email) {
            this.contactsService.findByEmail(this.userProfile.email)
                .toPromise()
                .then((contacts) => {
                    if (contacts.resources && contacts.resources[0] && contacts.resources[0].department) {
                        console.log('Found department by contact email: ' + contacts.resources[0].department);
                        this.department = contacts.resources[0].department ?? null;
                    } else {
                        this.department = null;
                    }
                }, () => this.department = null);
        } else {
            console.log('Found department: ' + department);
            this.department = department;
        }
    }
}
