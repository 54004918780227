import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {SettingsService} from '@services/settings/settings.service';
import {AuthorizationService} from '@services/authorization/authorization.service';
import {UserService} from '@services/user/user.service';
import {ApiAccountsService} from '@services/api-account/api-accounts.service';

@Injectable({
    providedIn: 'root'
})
export class AppAuthGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keycloakAngular: KeycloakService,
        protected readonly settingsService: SettingsService,
        protected readonly authorizationService: AuthorizationService,
        protected readonly userService: UserService,
        protected readonly apiAccounts: ApiAccountsService
    ) {
        super(router, keycloakAngular);
    }

    async isAccessAllowed(route: ActivatedRouteSnapshot): Promise<boolean> {
        if (!this.userService.userProfile) {
            console.log('loadProfile');
            await this.userService.loadUserProfile();
        }

        if (!this.settingsService.settings) {
            console.log('loadSettings');
            await this.settingsService.loadSettings();
        }

        if (!this.apiAccounts.apiAccounts) {
            console.log('loadEnabledApiKeys');
            await this.apiAccounts.loadEnabledApiKeys();
        }

        return this.getAccess(route);
    }

    private getAccess(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            if (!this.authenticated) {
                this.keycloakAngular.login({idpHint: 'saml-client'});
                return;
            }

            const module = route.data['module'];
            /**
             * Minimal permission level is READ unless it is defined on the route
             */
            const permission = route.data['permission'] || 'READ';
            if (
                this.settingsService.settings.roleAuthorizations === undefined ||
                this.settingsService.settings.roleAuthorizations.length === 0
            ) {
                /**
                 * If settings is set but roleAuthorizations is empty
                 * Validate if the current user is admin and grant access
                 */
                return resolve(this.roles.includes('admin'));
            } else if (!module) {
                return resolve(true);
            } else {
                return resolve(this.authorizationService.hasComponentAccess(module, permission));
            }
        });
    }
}
