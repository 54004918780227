import { Component, EventEmitter, Output } from '@angular/core';
import { ZynyoDocument } from '@shared/models/document/zynyo.document';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-modal-content',
    templateUrl: 'modal-content.component.html'
})
export class ModalContentComponent {
    get pending(): boolean {
        return this._pending;
    }

    set pending(value: boolean) {
        this._pending = value;
    }

    title!: string;
    message!: string;

    closeBtn = true;
    closeText = 'signrequest.close';

    cancelBtn = false;
    cancelClass = ' btn-outline-danger';
    cancelTxt = 'signrequest.cancel_modal';
    @Output() cancel = new EventEmitter();

    proceedBtn = false;
    proceedTxt!: string;
    proceedClass = 'nd-btn btn-secondary';
    @Output() proceed = new EventEmitter();

    zynyoDocument!: ZynyoDocument | null;

    private _pending = false;

    constructor(
        private readonly bsModalRef: BsModalRef
    ) {
    }

    cancelAction() {
        this.cancel.emit('cancel');
        this.bsModalRef.hide();
    }

    closeAction() {
        this.bsModalRef.hide();
    }


    proceedAction() {
        this.pending = true;
        this.proceed.emit(this.zynyoDocument);
    }
}
