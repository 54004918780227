import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { FormInput, FormInputOptions } from '@shared/models/form-input';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-form-input-multiselect',
    templateUrl: './form-input-multiselect.component.html',
    styleUrls: ['./form-input-multiselect.component.scss']
})
export class FormInputMultiselectComponent implements OnInit, OnChanges, OnDestroy {
    @Input() field!: FormInput;
    @Input() form!: UntypedFormGroup;
    @Input() options: FormInputOptions[] = [];

    selectedOptions: FormInputOptions[] = [];
    private subscription: Subscription = new Subscription();

    get Control() {
        return this.form.get(this.field.control) as UntypedFormControl;
    }

    private static convertOptionValue(options: FormInputOptions[]): string[] {
        return options.map(o => o.value);
    }

    ngOnInit() {
        if (this.field.options) {
            this.options = this.field.options;
        }

        if (this.options && this.Control.value) {
            this.selectedOptions = this.convertValueOption(this.Control.value);
        }

        this.subscription.add(this.Control.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged()
            ).subscribe(res => {
            if (res.length) {
                this.selectedOptions = this.convertValueOption(res);
            }
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.options && this.Control.value) {
            this.selectedOptions = this.convertValueOption(this.Control.value);
        }
    }

    addSelected(option: FormInputOptions) {
        if (this.isSelected(option.value)) {
            return;
        }

        this.selectedOptions.push(option);
        this.updateFormControl(FormInputMultiselectComponent.convertOptionValue(this.selectedOptions));
    }

    removeSelected(index: number) {
        this.selectedOptions.splice(index, 1);
        this.updateFormControl(FormInputMultiselectComponent.convertOptionValue(this.selectedOptions));
    }


    isSelected(value: string): boolean {
        return this.selectedOptions.some(o => o.value === value);
    }

    private updateFormControl(value: string[]) {
        this.Control.patchValue(value);
    }

    private convertValueOption(options: string[]): FormInputOptions[] {
        return this.options.filter(option => options.some(o => o === option.value));
    }
}
