
<div class="">
  <p class="section-title">{{ 'signrequest.send_date' | translate }}</p>
  <div class="workflow-info section">
    <p>{{ 'signrequest.send_date_description' | translate: {days: defaultSendDate  } }}</p>

    <div class="row">
      <div class="col-12 mb-3">
        <div class="btn-group">
          <button class="btn" [ngClass]="sendMethod === 'days' ? 'btn-primary': 'btn-outline-primary' " (click)="changeSendMethod('days')">{{ 'signrequest.send_date_component.days' | translate }}</button>
          <button class="btn" [ngClass]="sendMethod === 'specific'  ? 'btn-primary': 'btn-outline-primary'" (click)="changeSendMethod('specific')">{{ 'signrequest.send_date_component.date' | translate }}</button>
        </div>
      </div>
      <ng-container *ngIf="'days' === sendMethod; else showDatepicker">
        <div class="col-12">
          <label for="sendDate">{{ 'signrequest.send_date_days' | translate: {days: sendDate  } }}</label>
          <div class="input-group">
            <input id="sendDate"
                   class="form-control"
                   type="text"
                   [(ngModel)]="sendDate"
                   placeholder="{{ 'settings.send_date' | translate }}"/>
            <div class="input-group-append border-left-0">
              <span class="input-group-text">{{ 'signrequest.send_date_days_suffix' | translate }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #showDatepicker>
        <div class="col-12">
          <label for="datePicker">{{ 'signrequest.send_date_datepicker' | translate }}</label>
          <input type="text"
                 class="form-control pointer"
                 id="datePicker"
                 bsDatepicker
                 [bsConfig]="defaultConfig"
                 [value]="date | amDateFormat: this.defaultDateFormat"
                 [(bsValue)]="date"
                 (bsValueChange)="updateDate($event)">
        </div>
      </ng-template>
    </div>
  </div>
</div>
