import {Component, Input, OnInit} from '@angular/core';
import {Help} from '@shared/models/help/help';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-help-modal',
    templateUrl: './help-modal.component.html',
    styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit {

    @Input() help!: Help;

    constructor(
        private bsModalRef: BsModalRef
    ) {

    }

    ngOnInit() {
        console.log(this.help);
    }

    close(): void {
        this.bsModalRef.hide();
    }
}
