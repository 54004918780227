import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormInput} from '@shared/models/form-input';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-form-input-number',
  templateUrl: './form-input-number.component.html',
  styleUrls: ['./form-input-number.component.scss']
})
export class FormInputNumberComponent implements OnInit, OnDestroy {
    @Input() field!: FormInput;
    @Input() form!: UntypedFormGroup;
    @Input() readonly = false;
    @Input() inlineLabel = false;
    label = '';
    private subscription: Subscription = new Subscription();

    protected readonly Math = Math;

    constructor(private readonly translate: TranslateService) {
    }

    ngOnInit() {
        this.updateLabel();
        this.subscription.add(this.form.get(this.field.control)?.valueChanges.subscribe(x => {
            if (x < 1) {
                this.form.get(this.field.control)?.setValue(1);
            } else {
                this.updateLabel();
            }
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    get Control() {
        return this.form.get(this.field.control) as UntypedFormControl;
    }

    updateLabel() {
        const value = this.form.get(this.field.control)?.value;
        const days = Math.floor(value / 24);
        const hours = value % 24;
        this.label = (days > 0 ? days > 1 ? days + ' ' + this.translate.instant('apiAccounts.days') : days + ' ' + this.translate.instant('apiAccounts.day') : '') +
            ' ' + (days > 0 && hours > 0 ? this.translate.instant('apiAccounts.and') : '') + ' ' +
            (hours > 0 ? hours > 1 ? hours + ' ' + this.translate.instant('apiAccounts.hours') : hours + ' ' + this.translate.instant('apiAccounts.hour') : '');
    }

}
