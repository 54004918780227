import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { phoneNumberValidator } from '@utils/phone-number.validator';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-phone-number-input',
    templateUrl: './phone-number-input.component.html',
    styles: []
})
export class PhoneNumberInputComponent implements OnInit, OnChanges, OnDestroy {
    @Input() mobileOnly = false;
    @Input() placeHolder = '';
    @Input() invalid?: boolean;
    @Input() value?: string;
    @Output() update: EventEmitter<string> = new EventEmitter<string>();
    @Output() errors: EventEmitter<ValidationErrors | null> = new EventEmitter<ValidationErrors | null>();

    inputControl = new UntypedFormControl(null, []);
    private subscription: Subscription = new Subscription();

    ngOnInit() {
        this.inputControl.setValidators(phoneNumberValidator(this.mobileOnly));

        if (this.value) {
            this.inputControl.patchValue(this.value);
        }

        this.subscription.add(this.inputControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(300),
            )
            .subscribe(res => {
                this.errors.emit(this.inputControl.errors);
                this.update.emit(res);
            }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['value']?.previousValue !== changes['value']?.currentValue) {
            this.inputControl.patchValue(this.value);
        }
    }
}
