import {Injectable} from '@angular/core';
import {Help} from '@shared/models/help/help';
import {HateoasResourceOperation, HttpMethod} from '@lagoshny/ngx-hateoas-client';

@Injectable({
    providedIn: 'root'
})
export class HelpService extends HateoasResourceOperation<Help> {

    constructor() {
        super(Help);
    }

    getHelpByKey(key: string): Promise<Help> {
        return this.customQuery<Help>(HttpMethod.GET, 'search/findByKey?key=' + key)
            .toPromise()
            .then(res => {
                return res;
            })
            .catch((error) => {
                return error;
            });
    }

    getHelpById(id: number): Promise<Help> {
        return this.getResource(id)
            .toPromise()
            .then(res => {
                return res;
            }).catch((error) => {
                return error;
            });
    }

    getAllHelp() {
        return this.getCollection();
    }

    createHelp(help: Help): Promise<Help> {
        return this.createResource({body: help})
            .toPromise()
            .then(res => {
                return res;
            }).catch((error) => {
                return error;
            });
    }


    updateHelp(help: Help) {
        return this.updateResourceById(help.id, {body: help});
    }

    deleteHelp(help: Help) {
        return this.deleteResource(help);
    }
}
