import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { FormInputOptions } from '@shared/models/form-input';
import { TranslatorService } from '@services/translator/translator.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-language-input',
    templateUrl: './language-input.component.html',
    styleUrls: []
})
export class LanguageInputComponent implements OnInit, OnChanges, OnDestroy {
    @Input() value?: string;
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() errors: EventEmitter<ValidationErrors | null> = new EventEmitter<ValidationErrors | null>();
    private subscription: Subscription = new Subscription();

    readonly inputControl = new UntypedFormControl(null);
    readonly availableLanguages: FormInputOptions[];

    constructor(
        private readonly translator: TranslatorService
    ) {
        this.availableLanguages = this.translator.availableLanguagesOptions;
    }


    ngOnInit(): void {
        if (this.value) {
            this.inputControl.patchValue(this.value);
        }

        this.subscription.add(this.inputControl.valueChanges
            .pipe(
                distinctUntilChanged()
            )
            .subscribe(res => {
                this.valueChange.emit(res);
                this.errors.emit(this.inputControl.errors);
            }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['value'].previousValue !== changes['value'].currentValue) {
            this.inputControl.patchValue(this.value);
        }
    }
}
