import { Component, EventEmitter, Input, Output } from '@angular/core';
import {AuthMethod, ExclusiveMethodsArray} from '../../models/settings/auth-method';
import { EnabledAuthMethod } from '../../models/settings/enabled-auth-method';
import { SignSignatory } from '../../models/signrequest/sign.signatory';
import { Contact } from '../../models/contact/contact';
import {SignrequestUtils} from '@utils/signrequest-utils';

@Component({
    selector: 'app-auth-methods',
    templateUrl: './auth-methods.component.html',
    styleUrls: ['./auth-methods.component.scss']
})
export class AuthMethodsComponent {
    @Input() signatory!: Partial<SignSignatory | Contact>;
    @Input() enabled!: EnabledAuthMethod[];
    @Input() disabled: AuthMethod[] = [];
    @Input() previewMethod = false;
    @Input() exclusive?: AuthMethod;
    @Output() toggle: EventEmitter<AuthMethod> = new EventEmitter<AuthMethod>();

    exclusiveMethods = ExclusiveMethodsArray;

    toggleAuthMethod(method: EnabledAuthMethod) {
        this.toggle.emit(method.authenticationMethod);
    }

    isDisabled(enabledAuthMethod: EnabledAuthMethod): boolean {
        return this.disabled.includes(enabledAuthMethod.authenticationMethod)
            || (this.exclusive !== undefined
                && this.exclusiveMethods.includes(enabledAuthMethod.authenticationMethod)
                && enabledAuthMethod.authenticationMethod !== this.exclusive);
    }



    hasAuthMethod(enabledAuthMethod: EnabledAuthMethod): boolean {
        if (this.signatory instanceof Contact) {
            return this.signatory.authenticationMethods.includes(enabledAuthMethod.authenticationMethod);
        } else {
            const signatory = this.signatory as SignSignatory;

            return signatory.authenticationMethods.some(am =>
                SignrequestUtils.findMethodByType(am, enabledAuthMethod.authenticationMethod, this.previewMethod)
            );
        }
    }
}
