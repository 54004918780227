
<div class="auth-methods-list mb-4">
  <ng-container *ngFor="let authMethod of enabled | sortAuthMethods">
    <div class="checkbox c-checkbox"
         [ngClass]="{ 'selected': hasAuthMethod(authMethod), 'disabled': isDisabled(authMethod) }"
         ngbTooltip=" {{ 'signrequest.disabled_tooltip' | translate }}"
         [disableTooltip]="!isDisabled(authMethod)"
         >
      <label (click)="$event.stopPropagation()"
             [ngClass]="{ 'disabled': isDisabled(authMethod) }">
        <input type="checkbox"
               class="auth-method-checkbox"
               [checked]="hasAuthMethod(authMethod)"
               (change)="toggleAuthMethod(authMethod)"
               [disabled]="isDisabled(authMethod)">
        <span class="auth-method-name">{{ ('signrequest.am.' + authMethod.authenticationMethod) | translate }}</span>
      </label>
    </div>
  </ng-container>
</div>
