
<ng-container *ngIf="infoModal">
    <div class="modal-body" >

      <div class="text-center icon">
        <em [class]="infoModal.icon"></em>
      </div>

      <div class="content" [innerHTML]="infoModal.content"></div>

      <div class="text-center">
        <button class="btn btn-secondary" (click)="bsModalRef.hide()">Sluiten</button>
      </div>

    </div>
</ng-container>
