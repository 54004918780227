import { DocumentInfo } from './document.info';
import { SignSignatory } from './sign.signatory';
import { DocumentTemplate } from '../documenttemplate/document.template';
import { SignatureLabel } from './signature.label';
import { Settings } from '@shared/models/settings/settings';

export type DocumentMergeType = 'MERGE' | 'SEPARATE_REQUESTS';

export class SignDocumentRequest {

    documentInfo: DocumentInfo = new DocumentInfo('', '');
    signatories: SignSignatory[] = [];
    useTimeStamp = true;
    enableLTV = true;
    separateAuditlog: boolean | null = null;
    disableEmail = false;
    disableAttachment = false;
    content!: string[];
    reference!: string;
    documentTemplate!: DocumentTemplate | null;
    signatureLabel?: SignatureLabel;
    submitter!: string; // submitter email
    submitterName!: string;
    expireAfter!: number;
    sendDate!: string;
    mergeType!: DocumentMergeType;
    documentNames!: string[];

    setRequestSettings(settings: Settings) {
        if (settings.certificateLabel) {
            this.signatureLabel = new SignatureLabel();
            this.signatureLabel.xcoordinate = settings.certificateLabel.xcoordinate;
            this.signatureLabel.ycoordinate = settings.certificateLabel.ycoordinate;
            this.signatureLabel.width = settings.certificateLabel.width;
            this.signatureLabel.height = settings.certificateLabel.height;
            this.signatureLabel.pageIndex = settings.certificateLabel.pageIndex;
            if (settings.stampImage) {
                this.signatureLabel.stampImage = settings.stampImage.split(',')[1];
            }
        }

        if (settings.expireAfter) {
            this.expireAfter = settings.expireAfter;
        }
    }
}
