import { Component, Input } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { FormInput } from '@shared/models/form-input';

@Component({
  selector: 'app-form-input-text',
  templateUrl: './form-input-text.component.html',
  styleUrls: ['./form-input-text.component.scss']
})
export class FormInputTextComponent {
  @Input() field!: FormInput;
  @Input() form!: UntypedFormGroup;
  @Input() readonly = false;
  @Input() inlineLabel = false;

  get Control() {
    return this.form.get(this.field.control) as UntypedFormControl;
  }
}
