import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Role, SignSignatory } from '@shared/models/signrequest/sign.signatory';
import { AuthenticationMethod } from '@shared/models/signrequest/authentication/authentication-method';
import { EnabledAuthMethod } from '@shared/models/settings/enabled-auth-method';
import { SignrequestUtils } from '@utils/signrequest-utils';
import {AuthMethod, GlobalMethodsArray} from '@shared/models/settings/auth-method';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-sr-recipients',
    templateUrl: './sr-recipients.component.html',
    styleUrls: ['./sr-recipients.component.scss']
})
export class SrRecipientsComponent implements OnInit {
    @Input() recipientsList!: SignSignatory[];
    @Input() title = 'signrequest.signatories';
    @Input() description = 'signrequest.signatories_desc';
    @Input() addBtn = 'signrequest.add_signatory';
    @Input() minLength = 1;
    @Input() selectedSignatory!: SignSignatory;
    @Input() enabledAuthMethods: EnabledAuthMethod[] = [];
    @Input() roleType!: Role;
    @Output() selectedRecipient: EventEmitter<SignSignatory | null> = new EventEmitter<SignSignatory | null>();

    signatoryErrorMsg!: string | null;
    showError = false;

    ngOnInit(): void {
        if (this.roleType !== Role.CC) {
            if (!this.recipientsList.length) {
                this.addSignatory(true);
            } else {
                this.toggleSignatoryDetails(this.recipientsList[0]);
            }
        }
    }

    setDefaultAuthMethods(authMethods: EnabledAuthMethod[]): AuthenticationMethod[] {
        const bam: AuthenticationMethod[] = [];

        authMethods.forEach(am => {
            bam.push(SignrequestUtils.setAuthMethods(am.authenticationMethod));
        });

        return bam;
    }

    toggleSignatoryDetails(signatory: SignSignatory | null): void {
        let selected = signatory;
        if (selected === this.selectedSignatory) {
            selected = null;
        }
        this.selectedRecipient.emit(selected);
    }

    removeSignatory(signatory: SignSignatory): void {
        if (this.roleType !== Role.CC && this.recipientsList.length <= this.minLength) {
            return;
        }

        const index = this.recipientsList.indexOf(signatory);
        if (index >= 0) {
            this.recipientsList.splice(index, 1);
            this.toggleSignatoryDetails(null);
        }
    }

    addSignatory(init: boolean = false): void {
        const globalMethod = GlobalMethodsArray.find(am =>
          !!this.recipientsList.find(recipient =>
            recipient.authenticationMethods.find(recipam =>
              SignrequestUtils.findMethodByType(recipam, am))));

        const signatory = new SignSignatory();
        signatory.authenticationMethods = [];

        const defaultEnabled = this.recipientsList.length === 0 ? [
            ...this.enabledAuthMethods.filter(am => am.defaultEnabled),
        ]
        : [
            ...this.enabledAuthMethods.filter(am => am.defaultEnabled && !GlobalMethodsArray.includes(am.authenticationMethod)),
        ];
        signatory.authenticationMethods = this.setDefaultAuthMethods(defaultEnabled);
        if (globalMethod && !signatory.authenticationMethods.some(am => am.type === globalMethod)) {
            signatory.authenticationMethods.push(SignrequestUtils.setAuthMethods(globalMethod));
        }

        /**
         * The role is set to CC if no authMethods are available
         */
        if (!this.enabledAuthMethods.length && Role.CC === this.roleType) {
            signatory.signatoryRole = Role.CC;
        }

        if (Role.DELIVERY === this.roleType) {
            signatory.signatoryRole = Role.DELIVERY;
        }

        this.recipientsList.push(signatory);

        if (init || !this.selectedSignatory) {
            this.toggleSignatoryDetails(signatory);
        }
    }

    drop(event: CdkDragDrop<SignSignatory[]>) {
        moveItemInArray(this.recipientsList, event.previousIndex, event.currentIndex);
    }
}
