<ng-container *ngIf="recipient">
    <div class="mb-3">
        <div class="mb-3">
            <h4>{{ title }} &nbsp;</h4>
            <p class="small text-muted">{{ description }} &nbsp;</p>
        </div>
    </div>

    <div [ngClass]="{'border-warning': errorMsg}"
         class="p-3 border rounded border-primary">
        <ng-container *ngIf="errorMsg">
            <div class="form-group text-danger">{{ errorMsg | translate }}</div>
        </ng-container>

        <div class="form-group mb-3">
            <label>{{ 'contacts.name' | translate }} *</label>
            <div class="position-relative">
                <div class="input-group">
                    <input (click)="toggleContactList(true); filterContactsInput.focus()"
                           [(ngModel)]="recipient.name"
                           class="form-control border-right-0"
                           placeholder="{{ 'signrequest.name_and_last_name' | translate }}">
                    <div (click)="filterContacts(); toggleContactList(true)"
                         class="input-group-append cursor-pointer border-left-0 p-3">
                        <em class="fas fa-address-book fa-fw"></em>
                    </div>
                    <div #availableContactList
                         [ngClass]="{ 'hide': !showContactList }"
                         [style.zIndex]="200"
                         class="w-100 bg-white border border-primary position-absolute contact-listbox">
                        <div class="input-group border-bottom">
                            <input #filterContactsInput
                                   (input)="filterContacts()"
                                   [(ngModel)]="contactFilter"
                                   class="form-control border-0"
                                   placeholder="{{ 'signrequest.choose_contact' | translate }}..."
                                   type="text">

                            <div (click)="toggleContactList(false)"
                                 class="input-group-append cursor-pointer border-0 p-3">
                                <em class="fas fa-times fa-fw"></em>
                            </div>
                        </div>

                        <div class="contact-list">
                            <ng-container *ngIf="contactFilter && noContactFound; else ShowContactsList">
                                <div class="contacts p-3">
                                    <p class="text-warning mb-4"><em class="fa fa-fw fa-exclamation-triangle"></em>
                                        <span
                                            [innerHTML]="'signrequest.no_contacts_found' | translate: {name: contactFilter }"></span>
                                    </p>
                                    <button (click)="createContact(recipient)" class="btn btn-block btn-outline-warning"
                                            type="button"><em
                                        class="fas fa-plus mr-2"></em> {{ 'contacts.new_contact_name' | translate: {name: contactFilter} }}
                                    </button>
                                </div>
                            </ng-container>

                            <ng-template #ShowContactsList>
                                <div class="add-new-contact border-bottom">
                                    <button (click)="createContact(recipient)" class="btn btn-link btn-block"><em
                                        class="fas fa-plus mr-2"></em> {{ 'contacts.new_contact' | translate }}</button>
                                </div>
                                <div class="contacts p-3">
                                    <div *ngFor="let key of contactsMapKeys" class="letter-group">
                                        <div class="text-gray-dark d-flex">
                                            <strong class="mr-2">{{ key }}</strong>
                                            <hr class="my-2 w-100"/>
                                        </div>
                                        <div *ngFor="let contact of contactsMap.get(key)" class="contact pl-3">
                                            <ng-container *ngIf="!emailAdded(contact.email); else contactNotAvailable">
                                                <div (click)="selectAvailableContact(contact, recipient)"
                                                     class="contact-label">
                                                    {{ contact.name }}
                                                </div>
                                            </ng-container>

                                            <ng-template #contactNotAvailable>
                                                <span class="text-muted">{{ contact.name }}</span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="signatory-email mb-4">
            <div class="form-group">
                <label>{{ 'signrequest.email_address' | translate }} *</label>
                <app-email-input (change)="validateRecipient()"
                                 (errors)="emailErrors($event)"
                                 [(value)]="recipient.email"
                                 [placeholder]="'signrequest.email_address' | translate"></app-email-input>
            </div>
        </div>

        <div class="form-group mb-4">
            <label>{{ 'signrequest.language' | translate }}&nbsp;*</label>
            <app-language-input (change)="validateRecipient()"
                                [(value)]="recipient.locale"></app-language-input>
        </div>

        <ng-container *ngIf="settings.signatoryAcceptance && !isEdelivery">
            <ng-container *ngIf="recipient.signatoryRole !== 'CC'">
                <div class="form-group mb-4">
                    <label class="signatory-section-title">{{ 'signrequest.signatory_role' | translate }}</label>
                    <div class="selector-as-btn-list mb-3">
                        <div [class.selected]="'SIGN'===recipient.signatoryRole" class="checkbox c-checkbox">
                            <label>
                                <input (change)="setRole('SIGN')"
                                       [checked]="'SIGN'===recipient.signatoryRole"
                                       type="checkbox">
                                <span class="label">{{ 'signrequest.role_sign' | translate }}</span>
                            </label>
                        </div>
                        <div [class.selected]="'ACCEPTANCE'===recipient.signatoryRole" class="checkbox c-checkbox">
                            <label>
                                <input (change)="setRole('ACCEPTANCE')"
                                       [checked]="'ACCEPTANCE'===recipient.signatoryRole"
                                       type="checkbox">
                                <span class="label">{{ 'signrequest.role_acceptance' | translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="recipient.signatoryRole === 'SIGN' || recipient.signatoryRole === 'DELIVERY'">
            <ng-container *ngIf="enabledPreviewMethods.length">
                <div class="form-group mb-3">
                    <label>{{ 'signrequest.preview' | translate }}</label>
                    <app-auth-methods (toggle)="toggleAuthentication($event, true)"
                                      [enabled]="enabledPreviewMethods"
                                      [previewMethod]="true"
                                      [signatory]="recipient"
                                      [exclusive]="exclusiveMethod">
                    </app-auth-methods>
                </div>
            </ng-container>

            <ng-container *ngIf="!isEdelivery">
                <div class="form-group mb-3">
                    <label>{{ 'signrequest.authentication' | translate }}</label>

                    <app-auth-methods (toggle)="toggleAuthentication($event)"
                                      [enabled]="enabledAuthenticationMethods"
                                      [disabled]="disabledAuthenticationMethods"
                                      [exclusive]="exclusiveMethod"
                                      [signatory]="recipient">
                    </app-auth-methods>
                </div>
            </ng-container>

            <ng-container *ngIf="idinAuthPreviewMethod">
                <div [formGroup]="form" class="checkbox c-checkbox mb-3">
                    <label>
                        <label class="signatory-section-title mb-2 auto-validation">{{ 'edelivery.automatic_validation.idin' | translate}}</label>
                        <br>
                        <small class="text-muted">{{'edelivery.automatic_validation_desc' | translate}}</small>
                        <br>
                        <input type="checkbox"
                               [id]="'automaticValidationIDIN'"
                               formControlName="automaticValidationIDIN"
                               [checked]="this.form.get('automaticValidationIDIN')?.value"
                               (click)="onAutomaticValidationCheck($event, 'idin')">
                        <span class="fa fa-check fa-fw"></span>
                    </label>
                </div>
            </ng-container>

            <ng-container *ngIf="irmaAuthPreviewMethod">
                <div [formGroup]="form" class="checkbox c-checkbox mb-3">
                    <label>
                        <label class="signatory-section-title mb-2 auto-validation">{{ 'edelivery.automatic_validation.irma' | translate}}</label>
                        <small class="text-muted">{{'edelivery.automatic_validation_desc' | translate}}</small>
                        <div class="mt-2">
                            <input type="checkbox"
                                   [id]="'automaticValidationIRMA'"
                                   formControlName="automaticValidationIRMA"
                                   [checked]="this.form.get('automaticValidationIRMA')?.value"
                                   (click)="onAutomaticValidationCheck($event, 'irma')">
                            <span class="fa fa-check fa-fw"></span>
                        </div>
                    </label>
                </div>
            </ng-container>

            <ng-container *ngIf="smstanAuthMethod || smstanAuthPreviewMethod">
                <div [formGroup]="form" class="form-group mb-3 mt-2">
                    <label class="signatory-section-title mb-2">{{ 'contacts.phone' | translate }}*</label>
                    <small class="text-muted">{{'signrequest.phone_smstan' | translate}}</small>
                    <div class="mt-2">
                        <input [value]="recipient.phone || null"
                               class="form-control"
                               formControlName="phonenumber"
                               type="text">
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="(irmaAuthPreviewMethod && idinAuthPreviewMethod && automaticValidationIRMA.value && !automaticValidationIDIN.value)
            || (irmaAuthPreviewMethod && !idinAuthPreviewMethod && automaticValidationIRMA.value)">
                <div [formGroup]="form" class="form-group mb-3">
                    <label class="signatory-section-title mb-2">{{ 'signrequest.initials' | translate }}*</label>
                    <small class="text-muted">{{'signrequest.initials_irma' | translate}}</small>
                    <div class="mt-2">
                        <input [value]="irmaAuthPreviewMethod.initials || null"
                               class="form-control"
                               formControlName="initials"
                               type="text">
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="(irmaAuthPreviewMethod && idinAuthPreviewMethod && !automaticValidationIRMA.value && automaticValidationIDIN.value)
            || (!irmaAuthPreviewMethod && idinAuthPreviewMethod && automaticValidationIDIN.value)">
                <div [formGroup]="form" class="form-group mb-3">
                    <label class="signatory-section-title mb-2">{{ 'signrequest.initials' | translate }}*</label>
                    <small class="text-muted">{{'signrequest.initials_idin' | translate}}</small>
                    <input [value]="idinAuthPreviewMethod.initials || null"
                           class="form-control"
                           formControlName="initials"
                           type="text">
                </div>
            </ng-container>

            <ng-container
                *ngIf="irmaAuthPreviewMethod && idinAuthPreviewMethod && automaticValidationIRMA.value && automaticValidationIDIN.value">
                <div [formGroup]="form" class="form-group mb-3">
                    <label class="signatory-section-title mb-2">{{ 'signrequest.initials' | translate }}*</label>
                    <small class="text-muted">{{'signrequest.initials_both' | translate}}</small>
                    <input [value]="idinAuthPreviewMethod.initials || irmaAuthPreviewMethod.initials || null"
                           class="form-control"
                           formControlName="initials"
                           type="text">
                </div>
            </ng-container>

            <ng-container *ngIf="idinAuthPreviewMethod && automaticValidationIDIN.value">
                <div [formGroup]="form" class="form-group mb-3">
                    <label class="signatory-section-title mb-2">{{ 'signrequest.prefix' | translate }}</label>
                    <small class="text-muted">{{'signrequest.prefix_idin' | translate}}</small>
                    <input [value]="idinAuthPreviewMethod.legalLastNamePrefix || null"
                           class="form-control"
                           formControlName="prefix"
                           type="text">
                </div>
            </ng-container>

            <ng-container *ngIf="(irmaAuthPreviewMethod && idinAuthPreviewMethod && automaticValidationIRMA.value && !automaticValidationIDIN.value)
            || (irmaAuthPreviewMethod && !idinAuthPreviewMethod && automaticValidationIRMA.value)">
                <div [formGroup]="form" class="form-group mb-3">
                    <label class="signatory-section-title mb-2">{{ 'signrequest.lastName' | translate }}*</label>
                    <small class="text-muted">{{'signrequest.lastName_irma' | translate}}</small>
                    <input [value]="irmaAuthPreviewMethod.surname || null"
                           class="form-control"
                           formControlName="lastname"
                           type="text">
                </div>
            </ng-container>

            <ng-container *ngIf="(irmaAuthPreviewMethod && idinAuthPreviewMethod && !automaticValidationIRMA.value && automaticValidationIDIN.value)
            || (!irmaAuthPreviewMethod && idinAuthPreviewMethod && automaticValidationIDIN.value)">
                <div [formGroup]="form" class="form-group mb-3">
                    <label class="signatory-section-title mb-2">{{ 'signrequest.lastName' | translate }}*</label>
                    <small class="text-muted">{{'signrequest.lastName_idin' | translate}}</small>
                    <input [value]="idinAuthPreviewMethod.legalLastname || null"
                           class="form-control"
                           formControlName="lastname"
                           type="text">
                </div>
            </ng-container>

            <ng-container
                *ngIf="irmaAuthPreviewMethod && idinAuthPreviewMethod && automaticValidationIRMA.value && automaticValidationIDIN.value">
                <div [formGroup]="form" class="form-group mb-3">
                    <label class="signatory-section-title mb-2">{{ 'signrequest.lastName' | translate }}*</label>
                    <small class="text-muted">{{'signrequest.lastName_both' | translate}}</small>
                    <input [value]="idinAuthPreviewMethod.legalLastname || irmaAuthPreviewMethod.surname || null"
                           class="form-control"
                           formControlName="lastname"
                           type="text">
                </div>
            </ng-container>

            <div class="mb-3" [hidden]="exclusiveMethod !== 'documenttemplate'">
                <app-template-selector [selected]="selectedTemplate"
                                       (select)="selectTemplate($event)"
                                       (hasTemplates)="templatesAvailable($event)"
                                       [required]="true"></app-template-selector>
            </div>
        </ng-container>

        <ng-container *ngIf="noContactFound && availableContactList.hidden">
            <div class="contacts">
                <p class="text-warning mb-4"><em class="fa fa-fw fa-exclamation-triangle"></em>
                    <span [innerHTML]="'signrequest.no_contacts_found' | translate: {name: contactFilter }"></span>
                </p>
                <button (click)="createContact(recipient)" class="btn btn-block btn-outline-warning" type="button"><em
                    class="fas fa-plus mr-2"></em> {{ 'contacts.new_contact_name' | translate: {name: contactFilter} }}
                </button>
            </div>
        </ng-container>
    </div>
</ng-container>
