import { ConfigService } from '@services/config/config.service';
import { environment } from '../../environments/environment';

export class ConsoleLogging {
    constructor(
        private readonly configService: ConfigService
    ) {
        if (!configService.config?.debug ?? environment.debug) {
            // console.log('Debugging is disabled');
            // ConsoleLogging.disableLogging();
        }
    }

    private static disableLogging() {
        let method;
        const methods = [
            'assert',
            'clear',
            'count',
            'debug',
            'dir',
            'dirxml',
            'error',
            'exception',
            'group',
            'groupCollapsed',
            'groupEnd',
            'info',
            'log',
            'markTimeline',
            'profile',
            'profileEnd',
            'table',
            'time',
            'timeEnd',
            'timeStamp',
            'trace',
            'warn'
        ];
        let length = methods.length;

        // @ts-ignore
        const console = (window.console = window.console || {});

        while (length--) {
            method = methods[length];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            console[method] = (function () {});
        }
    }
}
