import {HateoasResource, Resource} from '@lagoshny/ngx-hateoas-client';

@HateoasResource('help')
export class Help extends Resource {
    id!: number;
    key!: string;
    content!: string;
    title!: string;
}

