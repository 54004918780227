export type AuthMethod = 'certificate' | 'checkedid' | 'csc' | 'digid' | 'digid_preview' | 'document_anchors'| 'documenttemplate' |
    'idin' | 'idin_preview' | 'irma' | 'irma_preview' | 'mouse' | 'mouse_preview' | 'smstan' | 'smstan_preview' | 'sso' | 'sso_preview' ;

export const AuthMethodsArray: AuthMethod[] = ['certificate', 'checkedid', 'csc', 'digid', 'document_anchors', 'documenttemplate', 'idin', 'irma', 'mouse', 'smstan', 'sso'];
export const PreviewMethodsArray: AuthMethod[] =  ['digid_preview', 'idin_preview', 'irma_preview', 'smstan_preview', 'sso_preview'];

export const ExclusiveMethodsArray: AuthMethod[] = ['csc', 'documenttemplate', 'mouse'];

export const GlobalMethodsArray: AuthMethod[] = ['documenttemplate', 'document_anchors'];

/**
 * Array with the AMs which are available in an eDelivery request
 */
export const EdeliveryAuthMethodsArray = ['digid_preview', 'idin_preview', 'irma_preview', 'mouse_preview', 'smstan_preview'];
