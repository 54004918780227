<ngx-datatable class="bootstrap"
               #dataTable
               [reorderable]="false"
               [headerHeight]="85"
               [footerHeight]="50"
               [limit]="rowLimit"
               [messages]="messages"
               [loadingIndicator]="isLoading"
               [columnMode]="columnMode.force"
               [rowHeight]="65"
               [rows]="data"
               [rowClass]="getRowClass">

    <ng-container *ngIf="isExpandable">
        <ngx-datatable-row-detail #dataTableRow
                                  rowHeight="auto"
                                  [template]="detailTemplate">
        </ngx-datatable-row-detail>

        <ngx-datatable-column [resizeable]="false"
                              [sortable]="false"
                              [draggable]="false"
                              [canAutoResize]="false"
                              [maxWidth]="100">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <div class="fas fa-fw"
                     [ngClass]="expanded ? 'fa-chevron-down':'fa-chevron-right'"
                     (click)="toggleExpandRow(row)">
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>

    <ng-container *ngFor="let column of columns; let first = first">
        <ngx-datatable-column [prop]="column.prop"
                              name="{{ column.label | translate }}"
                              [ngClass]="{ 'ml-5': first }">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <ng-container [ngSwitch]="column.show">
                    <ng-container *ngSwitchCase="'CHECK'">
                        <em class="fas fa-fw" [ngClass]="value ? 'fa-check text-success' : 'fa-minus text-muted'"></em>
                    </ng-container>
                    <ng-container *ngSwitchCase="'COUNTER'">
                        <ng-container *ngIf="value.length as count; else noLength">
                            <span class="badge badge-warning badge-pill">{{ count }}</span>
                        </ng-container>

                        <ng-template #noLength>
                            <em class="fas fa-fw fa-minus text-muted"></em>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DATE'">
                        <span class="">{{ value | amLocale:language | amDateFormat: 'LL' }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DATE-TIME'">
                        <span class="">{{ value | amLocale:language | amDateFormat: 'LLL' }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'MASKED'">
                        <button type="button" class="btn btn-link px-2" ngxClipboard [cbContent]="value"><em class="fas fa-clipboard"></em></button>
                        <span class="" (click)="toggleMasked(rowIndex)">{{ showMasked === rowIndex ? value : '**********' }}</span>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <span class="">{{value}}</span>
                    </ng-container>

                </ng-container>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>

    <ng-container *ngIf="actions.length">
        <ngx-datatable-column prop="actions"
                              name="{{ 'actions' | translate }}"
                              [sortable]="false"
                              [maxWidth]="100">
          <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
              <div class="text-center">
                <ng-container *ngFor="let action of actions">
                    <button class="btn btn-circle mr-2 btn-{{ action.btnClass }}"
                            (click)="emitAction($event, action.type, row)"
                            *accessControl="action.accessControl">
                        <em class="fas {{ action.icon }}"></em>
                    </button>
                </ng-container>
              </div>
          </ng-template>
        </ngx-datatable-column>
    </ng-container>
</ngx-datatable>
