import {EmbeddedResource, HateoasEmbeddedResource} from '@lagoshny/ngx-hateoas-client';

@HateoasEmbeddedResource(['roleAuthorizations'])
export class RoleAuthorization extends EmbeddedResource {
    role!: string;
    component!: string;
    permission: Permission = Permission.NONE;

    constructor() {
        super();
    }
    //
    // constructor(role: string, component: string) {
    //     super();
    //     this.role = role;
    //     this.component = component;
    //     this.permission = Permission.NONE;
    // }
}

export enum Permission {
    NONE = 'NONE',
    READ = 'READ',
    READWRITE = 'READWRITE'
}
