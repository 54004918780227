<div class="authentication" >
  <em class="fas fa-lock mr-2"
      *ngIf="authMethodEnabled('digid_preview') || authMethodEnabled('idin_preview') || authMethodEnabled('irma_preview') || authMethodEnabled('mouse_preview') || authMethodEnabled('smstan_preview')"
      [ngClass]="hasAuthMethod('digid', true) || hasAuthMethod('idin', true) || hasAuthMethod('irma', true) || hasAuthMethod('mouse',true) || hasAuthMethod('smstan',true)?'text-primary':'text-gray-light'"
      title="{{'signrequest.am.preview' | translate}}"></em>

  <em class="fas fa-mouse mr-2"  *ngIf="authMethodEnabled('mouse')" [ngClass]="hasAuthMethod( 'mouse', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.mouse' | translate}}"></em>
  <em class="fas fa-sms mr-2 " *ngIf="authMethodEnabled('smstan')" [ngClass]="hasAuthMethod('smstan', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.smstan' | translate}}"></em>
  <em class="fas fa-money-check mr-2" *ngIf="authMethodEnabled('idin')" [ngClass]="hasAuthMethod('idin', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.idin' | translate}}"></em>
  <em class="fas fa-id-card mr-2"  *ngIf="authMethodEnabled('irma')" [ngClass]="hasAuthMethod( 'irma', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.irma' | translate}}"></em>
  <em class="fas fa-user mr-2" *ngIf="authMethodEnabled('digid')" [ngClass]="hasAuthMethod( 'digid', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.digid' | translate}}"></em>
  <em class="fas fa-passport mr-2" *ngIf="authMethodEnabled('checkedid')" [ngClass]="hasAuthMethod( 'checkedid', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.checkedid' | translate}}"></em>
  <em class="fas fa-certificate mr-2" *ngIf="authMethodEnabled('csc')" [ngClass]="hasAuthMethod( 'csc', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.csc' | translate}}"></em>
  <em class="fas fa-signature mr-2" *ngIf="authMethodEnabled('documenttemplate')" [ngClass]="hasAuthMethod( 'documenttemplate', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.document_template' | translate}}"></em>
  <em class="fas fa-anchor mr-2" *ngIf="authMethodEnabled('document_anchors')" [ngClass]="hasAuthMethod( 'document_anchors', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.document_anchors' | translate}}"></em>
  <em class="fas fa-certificate mr-2" *ngIf="authMethodEnabled('certificate')" [ngClass]="hasAuthMethod( 'certificate', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.certificate' | translate}}"></em>
  <em class="fas fa-user mr-2" *ngIf="authMethodEnabled('sso')" [ngClass]="hasAuthMethod( 'sso', false)?'text-primary':'text-gray-light'" title="{{'signrequest.am.sso' | translate}}"></em>
</div>
