import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SignSignatory } from '@shared/models/signrequest/sign.signatory';
import {getAuthType} from '@shared/models/signrequest/authentication/base-authentication-method';

@Component({
    selector: 'app-sr-recipients-overview',
    templateUrl: './sr-recipients-overview.component.html',
    styleUrls: ['./sr-recipients-overview.component.scss']
})
export class SrRecipientsOverviewComponent {
    @Input() canChange = false;
    @Input() showOpen = false;
    @Input() label!: string;
    selected!: SignSignatory | null;
    @Output() update: EventEmitter<SignSignatory[]> = new EventEmitter<SignSignatory[]>();

    private _recipients: SignSignatory[] = [];

    get recipients(): SignSignatory[] {
        return this._recipients;
    }

    @Input() set recipients(value: SignSignatory[]) {
        this._recipients = value;
    }

    getAuthMethodsByType(recipient: SignSignatory, type = 'AFTERVIEW') {
        if (!recipient.authenticationMethods) {
            return [];
        }
        return recipient.authenticationMethods.filter(value => getAuthType(value) === type);
    }

    toggle(recipient: SignSignatory) {
        if (this.selected?.email === recipient.email) {
            this.selected = null;
        } else {
            this.selected = recipient;
        }
    }

    toggleDisableEmail(recipient: SignSignatory) {
        recipient.disableEmail = !recipient.disableEmail;
        this.update.emit(this.recipients);
    }

    toggleDisableInvitation(recipient: SignSignatory) {
        recipient.disableInvitation = !recipient.disableInvitation;
        this.update.emit(this.recipients);
    }
}
