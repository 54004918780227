<div class="modal-header">
  <div class="mx-auto"><img class="brand-logo-img" src="assets/img/zynyo-logo.svg" alt="Zynyo Logo" /></div>
</div>
<div class="modal-body">
  <ngb-carousel *ngIf="onboardingModals" [showNavigationArrows]="false" #ngbCarousel="ngbCarousel" (slide)="onSlide($event)" wrap="false">
  <ng-template ngbSlide *ngFor="let onboardingModal of onboardingModals" >
    <div class="text-center icon" id="{{onboardingModal.id}}">
      <em [class]="onboardingModal.icon"></em>
    </div>
    <div class="content">
      <h1 class="text-md text-primary">{{onboardingModal.title}}</h1>
      <p [innerHTML]="onboardingModal.content"></p>
      <div class="text-center" *ngIf="onboardingModal.btn">
        <button class="btn btn-sm btn-secondary mt-3" (click)="routeToLink()">{{onboardingModal.btn}}</button>
      </div>
    </div>
  </ng-template>
</ngb-carousel>

  <div class="onboarding-nav">
    <div class="nav-btns"  *ngIf="displayNav">
      <div class="float-left">
        <a class="btn" (click)="skip()">Overslaan</a>
      </div>
      <div class="float-right">
        <a class="btn" (click)="ngbCarousel.next()">Volgende</a>
      </div>
    </div>
  </div>
</div>
