<div class="mb-3">
    <div class="mb-3 mt-3">
        <h4>{{  'signrequest.submitter' | translate }}</h4>
        <p class="small text-muted">{{ 'signrequest.submitter_desc' | translate }}</p>
    </div>
</div>
<div class="p-3 border rounded border-primary"
     [ngClass]="{'border-warning': form.invalid }">
  <form [formGroup]="form" novalidate>
    <div class="mb-4">
      <div class="form-group">
        <label for="name">{{ 'signrequest.submitter_name' | translate }} *</label>
        <input class="form-control"
               formControlName="name"
               id="name"
               type="text">
      </div>
    </div>
    <div class="mb-4">
      <div class="form-group">
        <label>{{ 'signrequest.submitter_email' | translate }} *</label>
        <p class="text-muted mb-3">{{ 'signrequest.submitter_email_desc' | translate }}</p>
        <app-email-input [value]="emailControl?.value"
                         (valueChange)="patchEmail($event)"
                         (errors)="emailErrors($event)"
                         [placeholder]="'signrequest.email_address' | translate"></app-email-input>
      </div>
    </div>
  </form>
</div>
