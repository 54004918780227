import { Component, Input } from '@angular/core';
import { EnabledAuthMethod } from '../../models/settings/enabled-auth-method';
import { AuthMethod } from '../../models/settings/auth-method';
import { SignSignatory } from '@shared/models/signrequest/sign.signatory';
import {SignrequestUtils} from '@utils/signrequest-utils';

@Component({
    selector: 'app-auth-methods-indicator',
    templateUrl: './auth-methods-indicator.component.html',
    styleUrls: ['./auth-methods-indicator.component.scss']
})
export class AuthMethodsIndicatorComponent {
    @Input() signatory!: SignSignatory;
    @Input() enabled!: EnabledAuthMethod[];

    hasAuthMethod(authMethod: AuthMethod, preview: boolean): boolean {
        return this.signatory.authenticationMethods.some(am => SignrequestUtils.findMethodByType(am, authMethod, preview));
    }

    authMethodEnabled(authMethod: AuthMethod) {
        return this.enabled.some(value => value.authenticationMethod === authMethod);
    }
}
