<div class="card border rounded">

  <div class="card-header flex-between">
    <ng-content select="[header]"></ng-content>
  </div>

  <div class="card-body">
    <ng-content select="[body]"></ng-content>

    <ng-container *ngIf="periodControl">
      <div class="d-flex align-items-center mb-3">
        <span class="period-label">{{ 'charts.select_period' | translate }}</span>
        <input type="text"
               class="form-control-plaintext pointer col-2"
               bsDatepicker
               [bsConfig]="bsConfigStart"
               [value]="startDate | amDateFormat: 'MM YYYY'"
               [(bsValue)]="startDate"
               (bsValueChange)="updateDates()">
            <span>-</span>
            <input type="text"
                   class="form-control-plaintext pointer col-2"
                   bsDatepicker
                   [bsConfig]="bsConfigEnd"
                   [value]="endDate | amDateFormat: 'MM YYYY'"
                   [(bsValue)]="endDate"
                   (bsValueChange)="updateDates()">
        </div>
    </ng-container>
    <div #cardBodyRef>
      <ng-container *ngIf="renderChart && !chartError">
        <ng-container [ngSwitch]="chartType">
          <ng-container *ngSwitchCase="'doughnet'">
            <ngx-charts-pie-chart [view]="view"
                                  [results]="chartData"
                                  scheme="picnic"
                                  doughnut="true"
                                  [legendPosition]="legendPosition"
                                  [legend]="showLegend"
                                  [labels]="showLabels"
                                  (select)="onSelect($event)">
            </ngx-charts-pie-chart>
          </ng-container>

          <ng-container *ngSwitchCase="'pie'">
            <ngx-charts-pie-chart [view]="view"
                                  [results]="chartData"
                                  scheme="picnic"
                                  [legendPosition]="legendPosition"
                                  [legend]="showLegend"
                                  [labels]="showLabels"
                                  (select)="onSelect($event)">
            </ngx-charts-pie-chart>
          </ng-container>

          <ng-container *ngSwitchCase="'bar'">
              <ngx-charts-bar-horizontal [view]="view"
                                         [results]="chartData"
                                         scheme="picnic"
                                         [xAxis]="true"
                                         [yAxis]="true"
                                         [showYAxisLabel]="true"
                                         [showXAxisLabel]="true"
                                         (select)="onSelect($event)">
              </ngx-charts-bar-horizontal>
          </ng-container>

        </ng-container>
      </ng-container>

      <ng-container *ngIf="chartError">
<!--        <div class="alert alert-warning">{{ 'chart.error' | translate}}</div>-->
      </ng-container>
    </div>
  </div>
</div>
