import { EmbeddedResource, HateoasEmbeddedResource } from '@lagoshny/ngx-hateoas-client';
import { AuthMethod } from './auth-method';

@HateoasEmbeddedResource(['enabledAuthenticationMethods'])
export class EnabledAuthMethod extends EmbeddedResource {
    id!: number;
    authenticationMethod!: AuthMethod;
    defaultEnabled!: boolean;
    defaultAutomaticValidation?: boolean;
    dmtService!: 'SIGNING' | 'EDELIVERY';
}
