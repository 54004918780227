import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SearchQueryService } from '@services/searchquery/searchquery.service';
import { SearchQuery } from '../../models/searchquery/search.query';
import {Observable, OperatorFunction, Subscription} from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit, OnDestroy {
    @Input() placeholder = 'search';
    @Input() queryName!: string;
    @Input() documentStates = '';
    @Output() action: EventEmitter<Partial<SearchQuery>> = new EventEmitter<Partial<SearchQuery>>();
    @ViewChild(NgbTypeahead) ngbTypeahead!: NgbTypeahead;
    @ViewChild('AddButton') AddButton!: ElementRef;


    searchControl: UntypedFormControl;
    pendingAction = false;
    disableButton = false;
    inputPaddingRight = 15;
    private searchQueries: SearchQuery[] = [];
    private query = new SearchQuery();
    private maxResults = 10;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly searchQuery: SearchQueryService
    ) {
        this.searchControl = new UntypedFormControl(null);
    }

    readonly formatter = (query: SearchQuery) => query.query;

    ngOnInit() {
        this.subscription.add(this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map(res => {
                this.disableButton = !!res.query;
                if (this.AddButton) {
                    this.inputPaddingRight = this.AddButton.nativeElement.offsetWidth;
                }
                return res.query ? res : {query: res};
            })
        ).subscribe(res => this.action.emit(res)));

        if (this.queryName) {
            this.searchQuery.findAll();
            this.subscription.add(this.searchQuery.findByType(this.queryName).subscribe(res => this.searchQueries = res));
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    search: OperatorFunction<string, SearchQuery[]> = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(50),
            distinctUntilChanged(),
            map(query => !query.length ? [] : this.filterMatches(query))
        );
    }

    saveSearchQuery() {
        if (this.disableButton) {
            return;
        }
        this.pendingAction = true;
        this.query.query = this.searchControl.value;
        this.query.type = this.queryName;
        this.query.documentStates = this.documentStates;
        this.subscription.add(this.searchQuery.createSearchQuery(this.query)
            .subscribe(() => this.searchQuery.findAll())
            .add(() => this.pendingAction = false));
    }

    deleteSearchQuery(r: SearchQuery, e: MouseEvent) {
        e.stopPropagation();
        this.searchControl.patchValue('');
        this.subscription.add(this.searchQuery.deleteSearchQuery(r)
            .subscribe(() => this.searchQuery.findAll())
            .add(() => this.ngbTypeahead.dismissPopup()));
    }

    private filterMatches(query: string) {
        return this.searchQueries.filter(q => q.query.toLowerCase().indexOf(query.toLowerCase()) > -1).slice(0, this.maxResults);
    }
}
