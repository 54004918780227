<div>
    <input type="tel"
           class="form-control"
           [placeholder]="placeHolder"
           [formControl]="inputControl"
           [class.ng-invalid]="invalid || inputControl.invalid">
</div>

<div *ngIf="inputControl.errors && inputControl.touched" class="pt-2 text-danger">
  <p *ngIf="inputControl.getError('invalidPhoneNumber')" [innerHTML]="'form.invalidPhoneNumber' | translate: { phone: inputControl.value }"></p>
  <p *ngIf="inputControl.getError('invalidPhoneType')" [innerHTML]="'form.invalidPhoneType' | translate"></p>
</div>
