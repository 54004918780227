import { AuthMethod } from '@shared/models/settings/auth-method';
import { Smstan } from '@shared/models/signrequest/authentication/smstan';
import { Idin } from '@shared/models/signrequest/authentication/idin';
import { Irma } from '@shared/models/signrequest/authentication/irma';
import { Digid } from '@shared/models/signrequest/authentication/digid';
import { Mouse } from '@shared/models/signrequest/authentication/mouse';
import { Csc } from '@shared/models/signrequest/authentication/csc';
import { AmDocumentTemplate } from '@shared/models/signrequest/authentication/document-template';
import {AmDocumentanchor} from '@shared/models/signrequest/authentication/documentanchor';
import {AuthenticationMethod} from '@shared/models/signrequest/authentication/authentication-method';
import {Certificate} from '@shared/models/signrequest/authentication/certificate';
import {CheckedID} from '@shared/models/signrequest/authentication/checkedid';
import {getAuthType} from '@shared/models/signrequest/authentication/base-authentication-method';
import {Sso} from '@shared/models/signrequest/authentication/sso';

export class SignrequestUtils {
    static validateEmail(email: string) {
        // tslint:disable-next-line:max-line-length
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static findMethodByType(am: AuthenticationMethod, method: AuthMethod, preview = false) {
        // If its a preview method, remove _preview from the method name
        if (preview) {
            method = method.split('_')[0] as AuthMethod;
        }
        const type = method === 'document_anchors' ? 'documentanchor' : method;
        const authtype = preview ? 'PREVIEW' : 'AFTERVIEW';

        return am.type === type && getAuthType(am) === authtype;
    }

    static setAuthMethods(am: AuthMethod): AuthenticationMethod {
        let bam: AuthenticationMethod;
        const preview = am.endsWith('_preview');

        switch (am) {
            case 'certificate':
                bam = new Certificate();
                break;
            case 'checkedid':
                bam = new CheckedID();
                break;
            case 'csc': {
                bam = new Csc();
                break;
            }
            case 'digid':
            case 'digid_preview': {
                bam = new Digid();
                bam.authtype = preview ? 'PREVIEW' : 'AFTERVIEW';
                break;
            }
            case 'document_anchors': {
                bam = new AmDocumentanchor();
                break;
            }
            case 'documenttemplate': {
                bam = new AmDocumentTemplate();
                break;
            }
            case 'idin':
            case 'idin_preview': {
                bam = new Idin();
                bam.authtype = preview ? 'PREVIEW' : 'AFTERVIEW';
                break;
            }
            case 'irma':
            case 'irma_preview': {
                bam = new Irma();
                bam.authtype = preview ? 'PREVIEW' : 'AFTERVIEW';
                break;
            }
            case 'mouse':
            case 'mouse_preview': {
                bam = new Mouse();
                bam.authtype = preview ? 'PREVIEW' : 'AFTERVIEW';
                break;
            }
            case 'smstan':
            case 'smstan_preview': {
                bam = new Smstan();
                bam.authtype = preview ? 'PREVIEW' : 'AFTERVIEW';
                break;
            }
            case 'sso':
            case 'sso_preview': {
                bam = new Sso();
                bam.authtype = preview ? 'PREVIEW' : 'AFTERVIEW';
                break;
            }
        }

        return bam;
    }
}
