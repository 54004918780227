<ng-container *ngIf="field">
    <div class="form-group mb-3" [formGroup]="form" [ngClass]="{'d-flex align-items-center': inlineLabel }">
        <label [for]="field.control" class="text-bold">
            {{ field.label | translate }}

            <ng-container *ngIf="field.type === 'color'">
                <div>{{ Control?.value }}</div>
            </ng-container>
        </label>
        <input [type]="field.type"
               [id]="field.control"
               [formControlName]="field.control"
               [readonly]="readonly"
               class="form-control"
               [placeholder]="field.placeholder ? (field.placeholder | translate) : '' "/>
    </div>
</ng-container>
