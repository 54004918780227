import { SignDocumentRequest } from './signrequest/sign.document.request';
import {HateoasResource, Resource} from '@lagoshny/ngx-hateoas-client';

@HateoasResource('workflow')
export class Workflow extends Resource {
    id!: number | null;
    name!: string;
    information!: string;
    department!: string;
    signDocumentRequest!: SignDocumentRequest;
    signType!: SignType;
    fixed = true;
}


export enum SignType {
    DOCUMENTSIGN = 'DOCUMENTSIGN',
    QUICKSIGN = 'QUICKSIGN'
}
