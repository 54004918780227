import { Component } from '@angular/core';
import { Language, TranslatorService } from '@services/translator/translator.service';

@Component({
    selector: 'app-language-switch',
    templateUrl: './language-switch.component.html',
    styles: []
})
export class LanguageSwitchComponent {
    readonly availableLanguages: Language[];
    selectedLanguage: Language;

    constructor(
        private readonly translator: TranslatorService
    ) {
        this.selectedLanguage = this.translator.currentLanguage;
        this.availableLanguages = this.translator.availableLanguages;
    }

    setLanguage(language: Language) {
        this.translator.setLanguage(language).then(res => {
            this.translator.currentLanguage = res;
            this.selectedLanguage = this.translator.currentLanguage;
        });
    }
}
