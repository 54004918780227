import {Injectable} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ModalContentComponent} from '@shared/components/modal-content/modal-content.component';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ErrorDisplayService {
    constructor(
        private readonly modalService: BsModalService,
        private readonly router: Router
    ) {
    }

    displayError(errorTitle: string, errorMessage: string, url = '') {
        console.log(errorMessage);
        const initialState = {
            title: errorTitle,
            message: errorMessage,
            proceedBtn: false,
            closeBtn: !!url,
            cancelBtn: false,
        };
        this.modalService.show(ModalContentComponent, {initialState});
        if (!!url) {
            this.router.navigate([url]);
        }
    }
}
