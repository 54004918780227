import { AuthType, AuthTypeEnum, BaseAuthenticationMethod } from './base-authentication-method';

export class Idin extends BaseAuthenticationMethod {
    type = 'idin';
    authtype: AuthType = AuthTypeEnum.AFTERVIEW;
    initials?: string;
    automaticValidation?: boolean;
    legalLastNamePrefix?: string;
    legalLastname?: string;
}
