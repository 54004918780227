<div class="form-group">
    <div class="custom-file img-upload">
        <input type="file" #fileInput (change)="fileChangeEvent($event)" class="input-file">
        <div class="img-content" *ngIf="(fileType === 'image/png' || fileType === 'image/jpeg') && !fileError; else fileUpload">
            <ng-container *ngIf="FormControl?.value; else fileUpload">
                <img [src]="FormControl?.value" alt="preview-image"/>
            </ng-container>
        </div>
        <ng-template #fileUpload>
            <div class="empty-content file-container">
                <em class="fa fa-fw fa-file-upload fa-2x"></em>
            </div>
        </ng-template>
    </div>
    <div *ngIf="fileError" class="text-warning">{{ fileError }}</div>
</div>

<ng-container *ngIf="FormControl?.value && !fileError">
    <button class="btn btn-danger" (click)="removeFile()">{{ removeLabel | translate }}</button>
</ng-container>
