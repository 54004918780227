<ng-container *ngIf="'checkbox' === field.type; else ShowError" [formGroup]="form">
    <ng-template *ngTemplateOutlet="InputTemplate" [formGroupName]="field.group ? field.group : null"></ng-template>
</ng-container>

<ng-template #InputTemplate>
    <div class="form-group relative mb-3">
        <label [for]="field.control" class="text-bold" [ngClass]="{'sr-only': !field.placeholder}">{{ field.label | translate }}</label>
        <div class="c-checkbox mt-2">
            <label>
                <input type="checkbox"
                       [id]="field.control"
                       [formControl]="Control"
                       [checked]="Control?.value"
                       [attr.disabled]="disabled ? true : null">
                <span class="fa fa-check fa-fw"></span>
                <ng-container *ngIf="field.placeholder; else backupLabel">{{ field.placeholder | translate }}</ng-container>
                <ng-template #backupLabel>{{ field.label | translate }}</ng-template>
            </label>
        </div>
    </div>
</ng-template>

<ng-template #ShowError>
  <div class="form-group mb-3">
    {{ field.control }} verkeerde input type: {{ field.type }} ipv checkbox
  </div>
</ng-template>
