import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {SignDocumentRequestService} from "@routes/sign-request/services/sign-document-request.service";

export interface SrStep {
    path: string;
    label: string;
    hide?: boolean;
}

@Component({
    selector: 'app-sr-steps-header',
    templateUrl: './sr-steps-header.component.html',
    styles: []
})
export class SrStepsHeaderComponent implements OnInit, OnDestroy {
    @Input() steps!: SrStep[];
    @Input() key!: string;
    private _activeUrl!: string;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        readonly signDocumentRequest: SignDocumentRequestService
    ) {
    }

    ngOnInit() {
        if (this.activatedRoute.snapshot.firstChild) {
            this._activeUrl = this.activatedRoute.snapshot.firstChild?.url[0].path;
        }

        this.subscription.add(this.router.events.pipe(
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
            startWith(),
            map(res => res.urlAfterRedirects.split('/').pop())
        ).subscribe(urlpart => {
            if (urlpart) {
                this._activeUrl = urlpart;
            }
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    greaterOrEqual(index: number) {
        return index >= this.steps.findIndex(step => step.path === this._activeUrl);
    }
}
