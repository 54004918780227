import { Component, Input } from '@angular/core';
import { FormInput } from '@shared/models/form-input';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-input-checkbox',
    templateUrl: './form-input-checkbox.component.html',
    styleUrls: ['./form-input-checkbox.component.scss']
})
export class FormInputCheckboxComponent {
    @Input() field!: FormInput;
    @Input() form!: UntypedFormGroup;
    @Input() disabled?: boolean;

    get Control() {
        return this.form.get(this.field.control) as UntypedFormControl;
    }
}
