<div class="input-group group-autocomplete">
  <div class="input-group-prepend">
    <span class="input-group-text"><i class="fas fa-search"></i></span>
  </div>

  <ng-container *ngIf="!queryName; else searchQuery">
    <input type="text"
           class="form-control"
           [formControl]="searchControl"
           placeholder="{{ placeholder | translate }}"
           aria-label="Search">
  </ng-container>

  <ng-template #searchQuery>
    <span class="input-autocomplete">
      <input type="text"
             class="form-control"
             placeholder="{{ placeholder | translate }}"
             aria-label="Search"
             [formControl]="searchControl"
             [ngbTypeahead]="search"
             [inputFormatter]="formatter"
             [resultFormatter]="formatter"
             [resultTemplate]="rt"
             [style.paddingRight.px]="inputPaddingRight">
    </span>

    <div class="input-autocomplete-button" *ngIf="searchControl.value" #AddButton>
      <button type="button"
              class="btn"
              (click)="saveSearchQuery()"
              [disabled]="disableButton || !searchControl.value || pendingAction">
        <i class="fas fa-plus fa-fw"></i> {{ 'search_input.save_query' | translate }}
      </button>
    </div>
  </ng-template>
</div>


<ng-template #rt let-r="result" let-t="term">
  <span>
    <ngb-highlight [result]="r.query" [term]="t"></ngb-highlight>
    <span class="remove-item" (click)="deleteSearchQuery(r, $event)"><i class="fa fa-fw fa-trash"></i></span>
  </span>
</ng-template>
