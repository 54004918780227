import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(data: any[], key: string, value: string): any[] {
        if (!value || !key) {
            return data;
        }

        if (!data[0].hasOwnProperty(key)) {
            console.log('error key does not exist on object');
            return data;
        }

        const regex = new RegExp(value, 'i');
        return data.filter(i => i[key].match(regex));
    }

}
