import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnabledAuthMethod } from '@shared/models/settings/enabled-auth-method';
import { SignSignatory } from '@shared/models/signrequest/sign.signatory';

@Component({
    selector: 'app-sr-recipient',
    templateUrl: './sr-recipient.component.html',
    styleUrls: ['./sr-recipient.component.scss']
})
export class SrRecipientComponent {
    @Input() recipient!: SignSignatory;
    @Input() selected!: boolean;
    @Input() enabledAuthMethods!: EnabledAuthMethod[];
    @Output() selectedRecipient: EventEmitter<SignSignatory> = new EventEmitter<SignSignatory>();

    toggleSignatoryDetails(signatory: SignSignatory) {
        this.selectedRecipient.emit(signatory);
    }
}
