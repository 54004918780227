import { Component, OnInit } from '@angular/core';
import initHelpHero from 'helphero';
import { KeycloakService } from 'keycloak-angular';
import { Profile } from '@shared/models/user';
import { ConfigService } from '@services/config/config.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        private readonly keycloakService: KeycloakService,
        private readonly configService: ConfigService
    ) {
    }

    ngOnInit() {
        if (typeof window !== 'undefined' && this.configService.config?.helpheroAppId) {
            const helpHero = initHelpHero(this.configService.config.helpheroAppId);
            this.keycloakService.loadUserProfile()
                .then(profile => profile as Profile)
                .then((profile) => helpHero.identify(profile.id, { username: profile.username}));
        }
    }
}
