import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {LegendPosition} from '@swimlane/ngx-charts';

export interface ChartData {
    id: string;
    name: string;
    value: number;
}

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnChanges {
    readonly defaultConfig: Partial<BsDatepickerConfig> = {
        dateInputFormat: 'MM YYYY',
        selectFromOtherMonth: true,
        isAnimated: true,
        minMode: 'month',
        maxDate: moment().toDate()
    };
    view: any[] = [0, 400];
    showLabels = true;
    renderChart = false;
    chartError = false;
    bsConfigStart: Partial<BsDatepickerConfig> = {};
    bsConfigEnd: Partial<BsDatepickerConfig> = {};
    startDate: Date = moment().toDate();
    endDate: Date = moment().toDate();
    @Input() showLegend = false;
    @Input() legendPosition = LegendPosition.Right;
    @Input() chartType!: 'bar' | 'doughnet' | 'pie';
    @Input() title = 'undefined';
    @Input() chartData: ChartData[] = [];
    @Input() sourceData: ChartData[] = [];
    @Input() periodControl = false;
    @Output() period: EventEmitter<{ start: string, end: string }> = new EventEmitter();
    @ViewChild('cardBodyRef', { static: true }) container!: ElementRef;

    ngOnInit() {
        this.startDate.setDate(this.startDate.getDate() - 30)
        if (this.container.nativeElement) {
            this.view = [this.container.nativeElement.clientWidth, 400];
            this.renderChart = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.chartError = (!this.chartData.length || !this.sourceData.length);
    }

    onSelect(selected: string): void {
        const tmp = _.cloneDeep(this.chartData);
        if (this.isDataShown(selected)) {
            this.filterChartData(tmp, selected);
        } else {
            const src = this.sourceData.find(sd => sd.name === selected);
            this.filterChartData(tmp, selected, src?.value);
        }
        this.chartData = tmp;
    }

    updateDates() {
        this.bsConfigStart = {...this.defaultConfig, ...{maxDate: this.endDate}};
        this.bsConfigEnd = {...this.defaultConfig, ...{minDate: this.startDate}};
        this.period.emit({start: moment(this.startDate).format('YYYYMM'), end: moment(this.endDate).format('YYYYMM')});
    }

    private isDataShown(name: string) {
        return this.chartData.some(cd => (cd.name === name && cd.value !== 0));
    }

    private filterChartData(data: ChartData[], selected: string, value = 0) {
        return data.map(res => {
            if (res.name === selected) {
                res.value = value;
            }
            return res;
        });
    }
}
