import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingsService } from '@services/settings/settings.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { isNumeric } from 'rxjs/internal-compatibility';

type SendMethod = 'days' | 'specific';

@Component({
  selector: 'app-sr-send-date',
  templateUrl: './sr-send-date.component.html',
  styleUrls: ['./sr-send-date.component.scss']
})
export class SrSendDateComponent implements OnInit {
  @Input() sendDate!: string;
  @Output() update: EventEmitter<string> = new EventEmitter<string>();
  readonly defaultSendDate: number;
  readonly defaultDateFormat = 'DD-MM-YYYY';
  readonly defaultConfig: Partial<BsDatepickerConfig> = {
    selectFromOtherMonth: true,
    isAnimated: true,
    minDate: moment().toDate()
  };
  date = moment().toDate();
  sendMethod!: SendMethod;

  constructor(
      private settingsService: SettingsService
  ) {
    this.defaultSendDate = this.settingsService.settings.sendDate;
  }

  ngOnInit() {
    if (isNumeric(this.sendDate)) {
      this.sendMethod = 'days';
    } else {
      this.sendMethod = 'specific';
    }
  }

  updateDate(event: Date) {
    this.sendDate = moment(event).format(this.defaultDateFormat);
  }

  changeSendMethod(sendMethod: SendMethod) {
    this.sendMethod = sendMethod;
    if ('days' === sendMethod) {
      this.sendDate = String(this.defaultSendDate);
    } else {
      this.sendDate = moment(this.date).format(this.defaultDateFormat);
    }

    this.update.emit(this.sendDate);
  }
}
