import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Signatory } from '@shared/models/document/signatory';

@Component({
    selector: 'app-info',
    templateUrl: './emailstate.component.html',
    styleUrls: ['./emailstate.component.scss']
})
export class EmailstateComponent {
    get signatory(): Signatory {
        return this._signatory;
    }

    set signatory(value: Signatory) {
        this._signatory = value;
    }

    private _signatory!: Signatory;

    constructor(
        public bsModalRef: BsModalRef
    ) {
    }

    stateClass(state: string) {

        switch (state) {
            case 'SENT': {
                return '';
            }
            case 'BOUNCE':
            case 'SPAMREPORT': {
                return 'text-danger';
            }
            case 'DROPPED':
            case 'PROCESSED':
            case 'OPEN':
            case 'DELIVERED': {
                return 'text-success';
            }
            default: {
                return '';
            }
        }
    }
}
