import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormInput } from '@shared/models/form-input';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-input-select',
    templateUrl: './form-input-select.component.html',
    styleUrls: ['./form-input-select.component.scss']
})
export class FormInputSelectComponent {
    @Input() field!: FormInput;
    @Input() form!: UntypedFormGroup;
    @Input() readonly = false;
    @Input() inlineLabel = false;
    @Input() isRequired = false;
    @Output() selected: EventEmitter<string> = new EventEmitter<string>();

    get Control() {
        return this.form.get(this.field.control) as UntypedFormControl;
    }

    selectOption(event: any) {
        this.selected.emit(event);
    }
}
