import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';
import { KeycloakService } from 'keycloak-angular';
import { Permission, RoleAuthorization } from '@shared/models/settings/role-authorisation';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {
    private readonly _roles = this.keycloackService.getUserRoles();
    private _filteredMatrix: RoleAuthorization[] = [];

    constructor(
        private readonly settingsService: SettingsService,
        private readonly keycloackService: KeycloakService
    ) {}

    get isSysOp(): boolean {
        return this._roles.includes('sysop');
    }

    actionAllowed(component: string): boolean {
        const hasComponent = this._filteredMatrix.find(matrix => matrix.component === component);
        return hasComponent?.permission === Permission.READWRITE ?? false;
    }

    hasComponentAccess(component: string, permission: Permission = Permission.READWRITE): Promise<boolean> {
        let roles = this._roles;
        if ( this._roles.length > 1 ) {
            const admin = this._roles.find(value => value === 'admin');
            if ( admin ) {
                roles = [admin];
            }
        }

        return this.settingsService.roleAuthorizations.then(roleAutoriszations => {
            this._filteredMatrix = roleAutoriszations.filter(items => roles.includes(items.role));
            const userAccessComponent = this._filteredMatrix.find(matrix => matrix.component === component);
            if (userAccessComponent?.permission === Permission.READWRITE) {
                // Users permission equals READWRITE and READ permission on component gets ignored
                return true;
            } else {
                // Users permissions equals the requested permission on the component
                return userAccessComponent?.permission === permission ?? false;
            }
        });
    }
}
