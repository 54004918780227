import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function phoneNumberValidator(mobileOnly = false, regionCode = 'NL'): ValidatorFn {
    return (control): ValidationErrors | null => {
        const value = control.value;
        if (!value || value.length === 0) {
            return null;
        }

        let validNumber = false;

        try {
            const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(value, regionCode);

            validNumber = phoneNumberUtil.isValidNumber(phoneNumber);

            if (mobileOnly && validNumber) {
                validNumber = PhoneNumberType.MOBILE === phoneNumberUtil.getNumberType(phoneNumber)
                    || PhoneNumberType.FIXED_LINE_OR_MOBILE === phoneNumberUtil.getNumberType(phoneNumber);

                return validNumber ? null : {invalidPhoneType: true};
            } else {
                return validNumber ? null : {invalidPhoneNumber: {value: control.value}};
            }
        } catch (e) {
            return {invalidPhoneNumber: {value: control.value}};
        }
    };
}

export function validatePhoneNumber(mobileOnly = false, regionCode = 'NL') {
    return (number: string): boolean => {
        try {
            let validNumber = false;
            if (!number || number.length === 0) {
                return true;
            }

            const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(number, regionCode);
            validNumber = phoneNumberUtil.isValidNumber(phoneNumber);

            if (mobileOnly && validNumber) {
                validNumber = PhoneNumberType.MOBILE === phoneNumberUtil.getNumberType(phoneNumber)
                    || PhoneNumberType.FIXED_LINE_OR_MOBILE === phoneNumberUtil.getNumberType(phoneNumber);

                return validNumber;
            }
            return validNumber;
        } catch (e) {
            return false;
        }
    };
}
