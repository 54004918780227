import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MenuService } from '@services/menu/menu.service';
import { KeycloakService } from 'keycloak-angular';
import { ContactsService } from '@services/contacts/contacts.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    navCollapsed = false;
    menuItems: MenuItem[] = [];
    chevron = 'fa-chevron-down';
    username!: string;
    department!: string;
    isNavSearchVisible!: boolean;
    inAdministration = false;

    @ViewChild('navBar', {static: true}) navBar!: ElementRef;

    constructor(
        private readonly menu: MenuService,
        private readonly contactsService: ContactsService,
        private readonly keycloakService: KeycloakService,
        private readonly cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.isNavSearchVisible = false;

        this.keycloakService.loadUserProfile().then((keycloakProfile) => {
            this.username = keycloakProfile.firstName + ' ' + keycloakProfile.lastName;
            if (keycloakProfile.email) {
                this.contactsService.findByEmail(keycloakProfile.email);
            }
            const roles = this.keycloakService.getUserRoles().filter(role => role !== 'view-profile');
            this.menu.getMenu(roles).then(value => {
                this.menuItems = value;
                this.cdRef.detectChanges();
            });
        });

        this.setCollapse(false);
    }

    toggleSidebar() {
        this.setCollapse(!this.navCollapsed);
    }

    setCollapse(collapse: boolean) {
        this.navCollapsed = collapse;
    }

    logout() {
        this.keycloakService.logout();
    }
}
