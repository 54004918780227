import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { Role, SignSignatory } from '@shared/models/signrequest/sign.signatory';
import { ContactsService } from '@services/contacts/contacts.service';
import { Smstan } from '@shared/models/signrequest/authentication/smstan';
import {AuthMethod, ExclusiveMethodsArray, GlobalMethodsArray} from '@shared/models/settings/auth-method';
import * as _ from 'lodash';
import { Settings } from '@shared/models/settings/settings';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalContentComponent } from '@shared/components/modal-content/modal-content.component';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { SignrequestUtils } from '@utils/signrequest-utils';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AddContactComponent } from '@shared/components/add-contact/add-contact.component';
import { phoneNumberValidator, validatePhoneNumber } from '@utils/phone-number.validator';
import { EnabledAuthMethod } from '@shared/models/settings/enabled-auth-method';
import { Contact } from '@shared/models/contact/contact';
import { UserService } from '@services/user/user.service';
import { Idin } from '@shared/models/signrequest/authentication/idin';
import { Irma } from '@shared/models/signrequest/authentication/irma';
import { AuthenticationMethod } from '@shared/models/signrequest/authentication/authentication-method';
import { SignDocumentRequestService } from '@routes/sign-request/services/sign-document-request.service';
import { AmDocumentTemplate } from '@shared/models/signrequest/authentication/document-template';
import {BaseAuthenticationMethod} from '@shared/models/signrequest/authentication/base-authentication-method';
import {Subscription} from 'rxjs';
import {getAuthType} from '@shared/models/signrequest/authentication/base-authentication-method';
import {Sso} from '@shared/models/signrequest/authentication/sso';

@Component({
    selector: 'app-sr-recipient-details',
    templateUrl: './sr-recipient-details.component.html',
    styleUrls: ['./sr-recipient-details.component.scss'],
    providers: [BsModalService]
})
export class SrRecipientDetailsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() recipient!: SignSignatory;
    @Input() title!: string;
    @Input() description!: string;
    @Input() availableMethods!: EnabledAuthMethod[];
    @Input() addedRecipients: SignSignatory[] = [];
    @Input() settings!: Settings;
    @Input() readonly = false;
    @Input() isEdelivery = false;
    @Output() updateRecipient: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() invalidRecipient: EventEmitter<boolean> = new EventEmitter<boolean>();
    defaultEnabledAuthIDIN!: boolean;
    defaultEnabledAuthIRMA!: boolean;
    noContactFound = false;
    contactFilter!: string;
    contactsMap!: Map<string, Contact[]>;
    contactsMapKeys!: string[];
    contacts!: Contact[];
    allContacts: Contact[] = [];
    enabledAuthenticationMethods!: EnabledAuthMethod[];
    disabledAuthenticationMethods: AuthMethod[] = [];
    enabledPreviewMethods!: EnabledAuthMethod[];
    exclusiveMethod?: AuthMethod;
    showContactList = false;
    smstanAuthMethod?: Smstan;
    smstanAuthPreviewMethod?: Smstan;
    idinAuthPreviewMethod?: Idin;
    irmaAuthPreviewMethod?: Irma;
    ssoAuthMethod?: Sso;
    ssoAuthPreviewMethod?: Sso;
    errorMsg: string | null = null;
    selectedTemplate: string | null = null;
    readonly form: UntypedFormGroup;
    private bsModalRef!: BsModalRef;
    private tempAuthmethods: AuthenticationMethod[] = [];
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly contactsService: ContactsService,
        private readonly modalService: BsModalService,
        private readonly translateService: TranslateService,
        private readonly fb: UntypedFormBuilder,
        private readonly userService: UserService,
        private readonly signDocumentRequestService: SignDocumentRequestService,
    ) {
        this.form = this.fb.group({
            automaticValidationIDIN: [false],
            automaticValidationIRMA: [false],
            phonenumber: ['', [Validators.required, phoneNumberValidator(true)]],
            initials: ['', [Validators.required]],
            prefix: ['', []],
            lastname: ['', [Validators.required]]
        });
    }

    get phoneNumber() {
        return this.form.get('phonenumber');
    }

    get initials() {
        return this.form.get('initials');
    }

    get prefix() {
        return this.form.get('prefix');
    }

    get lastName() {
        return this.form.get('lastname');
    }

    get automaticValidationIDIN() {
        return this.form.get('automaticValidationIDIN');
    }

    get automaticValidationIRMA() {
        return this.form.get('automaticValidationIRMA');
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.toggleContactList(false);
    }

    ngOnInit() {
        this.getContacts();

        this.initializeAutomaticValidation();

        if (this.isEdelivery) {
            this.enabledPreviewMethods = this.availableMethods;
        } else {
            this.enabledAuthenticationMethods = this.availableMethods.filter(am => !am.authenticationMethod.endsWith('_preview'));
            this.enabledPreviewMethods = this.availableMethods.filter(am => am.authenticationMethod.endsWith('_preview'));
        }

        this.documentTemplateCheck();
        this.onRecipientUpdate();
        this.exclusiveCheck();

        this.phoneNumber?.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
        ).subscribe((res) => {
            this.recipient.phone = res;
            if (this.smstanAuthMethod) {
                this.smstanAuthMethod.mobilePhone = res;
            }
            if (this.smstanAuthPreviewMethod) {
                this.smstanAuthPreviewMethod.mobilePhone = res;
            }
            this.patchAuthMethodFields();
            this.validateRecipient();
        });

        this.initials?.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
        ).subscribe((res) => {
            if (this.idinAuthPreviewMethod && this.automaticValidationIDIN?.value) {
                this.idinAuthPreviewMethod.initials = res;
            }
            if (this.irmaAuthPreviewMethod && this.automaticValidationIRMA?.value) {
                this.irmaAuthPreviewMethod.initials = res;
            }
            this.patchAuthMethodFields();
            this.validateRecipient();
        });

        this.prefix?.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
        ).subscribe((res) => {
            if (this.idinAuthPreviewMethod) {
                this.idinAuthPreviewMethod.legalLastNamePrefix = res;
            }
            this.patchAuthMethodFields();
            this.validateRecipient();
        });

        this.lastName?.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
        ).subscribe((res) => {
            if (this.idinAuthPreviewMethod && this.automaticValidationIDIN?.value) {
                this.idinAuthPreviewMethod.legalLastname = res;
            }
            if (this.irmaAuthPreviewMethod && this.automaticValidationIRMA?.value) {
                this.irmaAuthPreviewMethod.surname = res;
            }
            this.patchAuthMethodFields();
            this.validateRecipient();
        });

        this.modalService.onHidden
            .pipe(
                filter(() => this.bsModalRef !== undefined && this.bsModalRef.content instanceof AddContactComponent),
                map(() => {
                    const newContact: Contact = this.bsModalRef.content.contact;
                    return (newContact.email && newContact.name) ? newContact : null;
                })
            ).subscribe((contact) => {
                if (contact && this.emailAdded(contact.email)) {
                    const initialState = {
                        message: this.translateService.instant('signrequest.duplicate_email', {'email': contact.email}),
                        title: this.translateService.instant('signrequest.duplicate_email_title')
                    };
                    this.bsModalRef = this.modalService.show(ModalContentComponent, {initialState});
                } else if (contact) {
                    this.allContacts.push(contact);
                    if (this.recipient != null) {
                        this.selectAvailableContact(contact, this.recipient);
                    }
                    this.contacts = this.allContacts;
                    this.initContactsMap();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['recipient'] && !changes['recipient'].firstChange && this.recipient) {
            this.contactFilter = '';
            this.documentTemplateCheck();
            this.onRecipientUpdate();
        }
        this.fixGlobals();
        this.exclusiveCheck();
        this.updateDisabledMethods();
    }

    ngOnDestroy() {
        if (this.contactFilter && !this.recipient.name) {
            this.recipient.name = this.contactFilter;
        }
        this.subscription.unsubscribe();
    }

    onRecipientUpdate() {
        this.onAuthMethodUpdate();
        this.phoneNumber?.patchValue(
            this.smstanAuthMethod?.mobilePhone
            || this.smstanAuthPreviewMethod?.mobilePhone
            || this.recipient?.phone
            || ''
        );
            this.initials?.patchValue(
                this.idinAuthPreviewMethod?.initials
                || this.irmaAuthPreviewMethod?.initials
                || undefined
            );
        this.prefix?.patchValue(
            this.idinAuthPreviewMethod?.legalLastNamePrefix
            || undefined
        );
        this.lastName?.patchValue(
            this.idinAuthPreviewMethod?.legalLastname
            || this.irmaAuthPreviewMethod?.surname
            || this.nameToLastName(this.recipient?.name)
            || undefined
        );
        this.patchAuthMethodFields();
        this.validateRecipient();
    }

    onRecipientSelect() {
        this.onAuthMethodUpdate();
        this.initials?.patchValue('');
        this.prefix?.patchValue('');
        this.lastName?.patchValue(this.nameToLastName(this.recipient?.name));
        this.phoneNumber?.patchValue(this.recipient?.phone || '');
        this.patchAuthMethodFields();
        this.validateRecipient();
    }

    onAuthMethodUpdate() {
        this.smstanAuthMethod =
            this.recipient.authenticationMethods.find(am => getAuthType(am) === 'AFTERVIEW' && am.type === 'smstan') as Smstan;
        this.smstanAuthPreviewMethod =
            this.recipient.authenticationMethods.find(am => getAuthType(am) === 'PREVIEW' && am.type === 'smstan') as Smstan;
        this.irmaAuthPreviewMethod =
            this.recipient.authenticationMethods.find(am => getAuthType(am) === 'PREVIEW' && am.type === 'irma') as Irma;
        this.idinAuthPreviewMethod =
            this.recipient.authenticationMethods.find(am => getAuthType(am) === 'PREVIEW' && am.type === 'idin') as Idin;
        this.ssoAuthMethod =
            this.recipient.authenticationMethods.find(am => getAuthType(am) === 'AFTERVIEW' && am.type === 'sso') as Sso;
        this.ssoAuthPreviewMethod =
            this.recipient.authenticationMethods.find(am => getAuthType(am) === 'PREVIEW' && am.type === 'sso') as Sso;
    }

    patchAuthMethodFields() {
        if (this.smstanAuthMethod) {
            this.smstanAuthMethod.mobilePhone = this.phoneNumber?.value;
        }
        if (this.smstanAuthPreviewMethod) {
            this.smstanAuthPreviewMethod.mobilePhone = this.phoneNumber?.value;
        }
        if (this.irmaAuthPreviewMethod) {
            this.automaticValidationIRMA?.patchValue((this.recipient.authenticationMethods
                .find(am => am.type === 'irma') as Irma).automaticValidation);
            this.irmaAuthPreviewMethod.initials = this.automaticValidationIRMA?.value ? this.initials?.value : undefined;
            this.irmaAuthPreviewMethod.surname = this.automaticValidationIRMA?.value ? this.lastName?.value : undefined;
        }
        if (this.idinAuthPreviewMethod) {
            this.automaticValidationIDIN?.patchValue((this.recipient.authenticationMethods
                .find(am => am.type === 'idin') as Idin).automaticValidation);
            this.idinAuthPreviewMethod.initials = this.automaticValidationIDIN?.value ? this.initials?.value : undefined;
            this.idinAuthPreviewMethod.legalLastname = this.automaticValidationIDIN?.value ? this.lastName?.value : undefined;
            this.idinAuthPreviewMethod.legalLastNamePrefix = this.automaticValidationIDIN?.value ? this.prefix?.value : undefined;
        }
    }

    documentTemplateCheck() {
        if (!this.isEdelivery) {
            this.selectedTemplate = this.signDocumentRequestService.template;
        }
    }

    nameToLastName(name?: string): string {
        if (!name) {
            return '';
        }
        return name.substring(name.lastIndexOf(' ') + 1);
    }

    filterContacts() {
        if (!this.contactFilter) {
            this.contacts = this.allContacts;
        } else {
            this.contacts = this.allContacts?.filter(contact => contact.name.toLowerCase().includes(this.contactFilter.toLowerCase()));
        }

        this.initContactsMap();
    }

    createContact(signatory: SignSignatory) {
        this.bsModalRef = this.modalService.show(
            AddContactComponent,
            Object.assign({}, {keyboard: false, ignoreBackdropClick: true})
        );

        const contact = new Contact();
        if (signatory.name || this.contactFilter) {
            contact.name = signatory.name || this.contactFilter;
        }
        if (signatory.email) {
            contact.email = signatory.email;
        }
        if (signatory.phone) {
            contact.phone = signatory.phone;
        }

        contact.locale = signatory.locale ?? 'nl';

        this.bsModalRef.content.setContact(contact, true);
        this.bsModalRef.content.setSubmitMode(AddContactComponent.SUBMIT_LOCAL);
    }

    /**
     * Return bool if email address matches with addedRecipients
     */
    emailAdded(email: string): boolean {
        return this.addedRecipients.some(recipient => email === recipient.email);
    }


    selectAvailableContact(contact: Contact, signatory: SignSignatory) {
        signatory.name = contact.name;
        signatory.email = contact.email;
        signatory.phone = contact.phone ?? '';
        signatory.privacyProtected = contact.privacyProtected ?? false;
        signatory.locale = contact.locale ?? 'nl';

        const authMethods = contact.authenticationMethods
          .filter(am => !this.disabledAuthenticationMethods.includes(am as AuthMethod));
        const globalMethod = this.findGlobalMethod();
        if (globalMethod) {
            authMethods.push(globalMethod);
        }

        if (authMethods.length) {
            this.exclusiveMethod = undefined;
            signatory.authenticationMethods = [];
        }

        this.enabledPreviewMethods
            .filter(am => am.defaultEnabled)
            .map(am => this.toggleAuthentication(am.authenticationMethod, true));

        this.initializeContactAuthMethods(authMethods);

        this.onRecipientSelect();
        this.toggleContactList(false);
    }

    /**
     * Set recipients role for the signing process
     * @param signRole
     */
    setRole(signRole: string): void {
        this.recipient.signatoryRole = Role[signRole as Role];

        if (signRole === 'SIGN') {
            this.recipient.authenticationMethods = this.tempAuthmethods;
        } else {
            this.tempAuthmethods = this.recipient.authenticationMethods;
            this.recipient.authenticationMethods = [];
        }
        this.signDocumentRequestService.validate();
        this.errorMsg = this.recipientErrorMsg();

    }

    toggleAuthentication(method: AuthMethod, preview = false) {
        if (this.isEdelivery && !preview && !method.endsWith('_preview')) {
            return;
        }

        if (this.exclusiveMethod === method) {
            this.exclusiveMethod = undefined;
        } else if (ExclusiveMethodsArray.includes(method)) {
            this.exclusiveMethod = method;
        }
        const authmethods = _.cloneDeep(this.recipient.authenticationMethods);

        console.log('Init toggle');
        console.log(authmethods);
        const index = authmethods.findIndex(am => SignrequestUtils.findMethodByType(am, method, preview));

        if ( index === -1 ) {
            const newAuthMethod = SignrequestUtils.setAuthMethods(method);

            if (newAuthMethod) {
                authmethods.push(newAuthMethod);
                if (method === 'idin_preview') {
                    (newAuthMethod as Idin).automaticValidation = this.defaultEnabledAuthIDIN;
                } else if (method === 'irma_preview') {
                    (newAuthMethod as Irma).automaticValidation = this.defaultEnabledAuthIRMA;
                }
                console.log(newAuthMethod);
            }
        } else {
            authmethods.splice(index, 1);
        }

        if (ExclusiveMethodsArray.includes(method) || GlobalMethodsArray.includes(method)) {
            const baseAuthMethod = SignrequestUtils.setAuthMethods(method);
            this.toggleExclusiveMethod(baseAuthMethod, -1 === index, GlobalMethodsArray.includes(method));
        }

        this.recipient.authenticationMethods = authmethods;
        if ('documenttemplate' === method) {
            if (-1 !== index) {
                this.selectedTemplate = null;
            }
        }

        console.log(authmethods);
        this.onRecipientUpdate();
    }

    isSmsAuthMethod(am: AuthenticationMethod): am is Smstan {
        return am.type === 'smstan';
    }

    isIdinAuthMethod(am: AuthenticationMethod): am is Idin {
        return am.type === 'idin';
    }

    isIrmaAuthMethod(am: AuthenticationMethod): am is Irma {
        return am.type === 'irma';
    }

    isDocumentTemplateAuthMethod(am: AuthenticationMethod): am is AmDocumentTemplate {
        return am.type === 'documenttemplate';
    }

    toggleExclusiveMethod(method: BaseAuthenticationMethod, checked: boolean, global: boolean) {
        this.addedRecipients
            .filter(i => 'SIGN' === i.signatoryRole)
            .forEach(recipient => {
                const index = recipient.authenticationMethods
                    .findIndex(am => SignrequestUtils.findMethodByType(am, method.type as AuthMethod));
                // Remove am with match and checked is false
                if (!checked && (-1 !== index) && global) {
                    recipient.authenticationMethods.splice(index, 1);
                    this.exclusiveMethod = undefined;
                }

                // Add am if recipient doesn't have the required am
                if (checked && (-1 === index && global)) {
                    recipient.authenticationMethods.push(method);
                    this.exclusiveMethod = method.type as AuthMethod;
                }
            });
    }

    validateRecipient() {
        let recipientInvalid: boolean;
        if (!this.recipient.name && !this.recipient.email) {
            // Empty recipient, mark as invalid without error message
            this.errorMsg = null;
            recipientInvalid = true;
        } else {
            this.errorMsg = this.recipientErrorMsg();
            recipientInvalid = !!this.errorMsg;
        }
        this.invalidRecipient.emit(recipientInvalid);
        this.updateRecipient.emit();
    }

    recipientErrorMsg(): string | null {
        if (!!this.signDocumentRequestService.getSignatoriesByRole([Role.ACCEPTANCE]).length
            && !this.signDocumentRequestService.getSignatoriesByRole([Role.SIGN, Role.DELIVERY]).length) {
            return this.translateService.instant('signrequest.signatory_atleast_one_sign');
        }

        if (!this.recipient.name) {
            return this.translateService.instant('signrequest.signatory_name_error');
        }

        if (!this.recipient.email) {
            return this.translateService.instant('signrequest.signatory_email_error');
        } else if (
            this.addedRecipients.filter(signatory => signatory.email?.toLowerCase() === this.recipient.email?.toLowerCase()).length > 1
        ) {
            return this.translateService.instant('signrequest.duplicate_email', {'email': this.recipient.email});
        }

        const requiredAmRoles = [Role.SIGN, Role.DELIVERY];

        if (requiredAmRoles.includes(this.recipient.signatoryRole)) {
            if (!this.isEdelivery
                && !this.recipient.authenticationMethods.filter(am => getAuthType(am) === 'AFTERVIEW').length) {
                return this.translateService.instant('signrequest.authmethod_error.emtpy');
            }
            if (this.isEdelivery && !this.recipient.authenticationMethods.filter(am => getAuthType(am) === 'PREVIEW').length) {
                return this.translateService.instant('edelivery.authmethod_error.emtpy');
            }
            for (const am of this.recipient.authenticationMethods) {
                if (this.isSmsAuthMethod(am)) {
                    if (!this.phoneNumber?.value?.length) {
                        return this.translateService.instant('signrequest.authmethod_error.nophone');
                    }
                    if (!this.phoneNumber?.value?.length || !validatePhoneNumber(true)(this.phoneNumber?.value)) {
                        return this.translateService.instant('signrequest.authmethod_error.wrongphone');
                    }
                } else if (this.isIdinAuthMethod(am) && getAuthType(am) === 'PREVIEW') {
                    if (this.automaticValidationIDIN?.value) {
                        if (!this.initials?.value?.length) {
                            return this.translateService.instant('signrequest.authmethod_error.noinitials');
                        }
                        if (!this.lastName?.value?.length) {
                            return this.translateService.instant('signrequest.authmethod_error.nolastname');
                        }
                    }
                } else if (this.isIrmaAuthMethod(am) && getAuthType(am) === 'PREVIEW') {
                    if (this.automaticValidationIRMA?.value) {
                        if (!this.initials?.value?.length) {
                            return this.translateService.instant('signrequest.authmethod_error.noinitials');
                        }
                        if (!this.lastName?.value?.length) {
                            return this.translateService.instant('signrequest.authmethod_error.nolastname');
                        }
                    }
                } else if (this.isDocumentTemplateAuthMethod(am)) {
                    if (!this.signDocumentRequestService.template) {
                        return this.translateService.instant('signrequest.authmethod_error.notemplate');
                    }
                }
            }
        }
        return null;
    }

    emailErrors(errors: ValidationErrors) {
        if (errors) {
            this.errorMsg = 'form.invalidEmail';
            this.invalidRecipient.emit(true);
            this.updateRecipient.emit();
        }
    }

    toggleContactList(toggle: boolean) {
        this.showContactList = toggle;
        if (!toggle) {
            if (this.contactFilter && !this.recipient.name) {
                this.recipient.name = this.contactFilter;
            }

            this.contacts = this.allContacts;
            this.initContactsMap();
        }
    }

    selectTemplate(event: string | null) {
        this.selectedTemplate = event;
        this.signDocumentRequestService.template = event;
        this.validateRecipient();
    }

    templatesAvailable(available: boolean) {
        if (!available) {
            this.disabledAuthenticationMethods.push('documenttemplate');
        }
    }

    updateDisabledMethods() {
        this.disabledAuthenticationMethods = [];

        for (const am of this.availableMethods) {
            if (ExclusiveMethodsArray.includes(am.authenticationMethod) &&
                (this.exclusiveMethod !== undefined
                && this.exclusiveMethod !== am.authenticationMethod ||
                !!this.addedRecipients
                    .filter(recipient => !!recipient.authenticationMethods)
                    .find(recipient =>
                            recipient.authenticationMethods.find(recipam => {
                                return ExclusiveMethodsArray.includes(recipam.type as AuthMethod) &&
                                    recipam.type as AuthMethod !== am.authenticationMethod;
                            })))) {
                this.disabledAuthenticationMethods.push(am.authenticationMethod);
            }
        }
    }

    /**
     * Initiate new Map from contacts sorted by name
     * @private
     */
    private initContactsMap() {
        const contactsMap: Map<string, Contact[]> = new Map();
        if (this.contacts) {
            const sortedContacts: Contact[] = _.orderBy(this.contacts, [contact => contact.name.toLowerCase()]);

            for (const contact of sortedContacts) {
                const firstLetter = (contact.name.substring(0, 1)).toUpperCase();
                if (contactsMap.has(firstLetter)) {
                    const lContacts = contactsMap.get(firstLetter);
                    if (lContacts && lContacts.length) {
                        lContacts.push(contact);
                        contactsMap.set(firstLetter, lContacts);
                    }
                } else {
                    const lContacts: Contact[] = [contact];
                    contactsMap.set(firstLetter, lContacts);
                }
            }
        }

        this.contactsMap = contactsMap;
        this.contactsMapKeys = Array.from(contactsMap.keys());
        this.noContactFound = !this.contactsMapKeys.length;
    }

    private getContacts() {
        const department = this.userService.department;
        let service;
        if (department && this.settings.departmentFilterContacts) {
            service = this.contactsService.findByDepartment(department);
        } else {
            service = this.contactsService.findAll();
        }

        this.subscription.add(service.subscribe((contacts) => {
            this.contacts = contacts.resources;
            this.allContacts = contacts.resources;
            this.initContactsMap();
        }));
    }

    addRole($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
        const roleValue = ($event.target as HTMLInputElement).value;
        if ( roleValue === '' ) {
            return;
        }
        let roles = this.recipient.roles;
        if ( !roles ) {
            roles = [];
        }
        if ( roles.indexOf(roleValue) > -1 ) {
            return;
        }
        roles?.push( roleValue );
        this.recipient.roles = roles;
        ($event.target as HTMLInputElement).value = '';
    }

    removeRole(role: string): void {
        if ( !this.recipient.roles ) {
            return;
        }
        const roles = this.recipient.roles;
        if  ( roles ) {
            roles.splice(roles.indexOf(role), 1);
        }
        if ( roles.length > 0 ) {
            this.recipient.roles = roles;
        } else {
            this.recipient.roles = null;
        }
    }

    initializeContactAuthMethods(authMethods: string[]) {
        const exclusives: AuthMethod[] = authMethods.filter(
            (method) => ExclusiveMethodsArray.includes(method as AuthMethod)) as AuthMethod[];
        if (exclusives.length > 0) {
            if (exclusives.includes('documenttemplate')) {
                this.toggleAuthentication('documenttemplate');
                this.exclusiveMethod = 'documenttemplate';
            } else if (exclusives.includes('mouse')) {
                this.toggleAuthentication('mouse');
                this.exclusiveMethod = 'mouse';
            } else if (exclusives.includes('csc')) {
                this.toggleAuthentication('csc');
                this.exclusiveMethod = 'csc';
            }
        }
        authMethods
            .filter(method => !exclusives.includes(method as AuthMethod))
            .forEach(method => this.toggleAuthentication(method as AuthMethod));
    }

    exclusiveCheck() {
        for (const method of this.recipient.authenticationMethods) {
            if (ExclusiveMethodsArray.includes(method.type as AuthMethod)) {
                this.exclusiveMethod = method.type as AuthMethod;
                return;
            }
        }
        this.exclusiveMethod = undefined;
    }

    fixGlobals() {
        console.log(this.addedRecipients);
        this.addedRecipients.forEach(recipient => {
            if (recipient.authenticationMethods == null) {
                recipient.authenticationMethods = [];
            }
        });
        const globalMethod = this.findGlobalMethod();
        if (globalMethod) {
            this.addedRecipients.forEach(recipient => {
                recipient.authenticationMethods = recipient.authenticationMethods.filter(am =>
                    !ExclusiveMethodsArray.includes(am.type as AuthMethod) || am.type as AuthMethod === globalMethod
                );
            });
        }
    }

    findGlobalMethod(): AuthMethod | undefined {
        return GlobalMethodsArray.find(am =>
          !!this.addedRecipients.find(recipient =>
            recipient.authenticationMethods.find(recipam =>
              recipam.type === am)));
    }

    onAutomaticValidationCheck(event: MouseEvent, authName: string) {
        if (authName === 'idin') {
            this.automaticValidationIDIN?.patchValue(!this.automaticValidationIDIN.value);
            (this.recipient.authenticationMethods.find(am => am.type === 'idin') as Idin).automaticValidation =
                this.automaticValidationIDIN?.value;
        } else if (authName === 'irma') {
            this.automaticValidationIRMA?.patchValue(!this.automaticValidationIRMA.value);
            (this.recipient.authenticationMethods.find(am => am.type === 'irma') as Irma).automaticValidation =
                this.automaticValidationIRMA?.value;
        }
        this.onRecipientUpdate();
    }

    initializeAutomaticValidation() {
        const hasAutomaticValidationIDIN = this.settings.enabledAuthenticationMethods
            .find(am => am.authenticationMethod === 'idin_preview' && am.dmtService === 'EDELIVERY')
            ?.defaultAutomaticValidation;
        this.automaticValidationIDIN?.patchValue(hasAutomaticValidationIDIN ? hasAutomaticValidationIDIN : false);
        this.defaultEnabledAuthIDIN = hasAutomaticValidationIDIN ? hasAutomaticValidationIDIN : false;

        const hasAutomaticValidationIRMA = this.settings.enabledAuthenticationMethods
            .find(am => am.authenticationMethod === 'irma_preview' && am.dmtService === 'EDELIVERY')
            ?.defaultAutomaticValidation;
        this.automaticValidationIRMA?.patchValue(hasAutomaticValidationIRMA ? hasAutomaticValidationIRMA : false);
        this.defaultEnabledAuthIRMA = hasAutomaticValidationIRMA ? hasAutomaticValidationIRMA : false;
    }
}
