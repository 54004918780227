<div class="row header">
  <div class="steps-header col-12 px-3 px-lg-4">
    <ul>
      <ng-container *ngFor="let step of steps; let i = index">
          <ng-container *ngIf="!step.hide">
            <li [routerLink]="step.path" class="step-col" [ngClass]="{ 'step-primary': greaterOrEqual(i) }">
              <label>{{ step.label | translate }}</label>
              <div class="step-point"></div>
            </li>
          </ng-container>
      </ng-container>
    </ul>
      <div class="d-flex flex-row">
          <app-help-content [key]="key + steps[signDocumentRequest.currentStep].path" class="mx-2"></app-help-content>
          <app-language-switch class="ml-2"></app-language-switch>
      </div>
  </div>
</div>


