export abstract class BaseAuthenticationMethod {
    ordernumber!: number;
    type!: string;
    authtype?: AuthType;
}

export function getAuthType(am: BaseAuthenticationMethod) {
    return am.authtype === undefined ? AuthTypeEnum.AFTERVIEW : am.authtype;
}

export type AuthType = 'AFTERVIEW' | 'PREVIEW';
export enum AuthTypeEnum {
    AFTERVIEW = 'AFTERVIEW',
    PREVIEW = 'PREVIEW'
}
