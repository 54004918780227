import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DocumenttemplatesService } from '@routes/workflow/services/documenttemplates.service';
import { DocumentTemplate } from '@shared/models/documenttemplate/document.template';
import {Observable, Subscription} from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ApiAccountsService } from '@services/api-account/api-accounts.service';

@Component({
    selector: 'app-template-selector',
    templateUrl: './template-selector.component.html',
    styleUrls: ['./template-selector.component.scss']
})
export class TemplateSelectorComponent implements OnInit, OnChanges, OnDestroy {
    @Input() selected!: string | null;
    @Input() required = false;
    @Output() select: EventEmitter<string | null> = new EventEmitter<string | null>();
    @Output() hasTemplates: EventEmitter<boolean> = new EventEmitter<boolean>();
    readonly template = new UntypedFormControl();
    readonly documenttemplates$!: Observable<DocumentTemplate[]>;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly documenttemplateService: DocumenttemplatesService,
        private readonly apiAccountsService: ApiAccountsService
    ) {
        this.subscription.add(this.template.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(uuid => this.select.emit(uuid)));

        this.documenttemplateService.ApiKey = this.apiAccountsService.ApiKey;
        this.documenttemplates$ = this.documenttemplateService
            .getDocumentTemplates()
            .pipe(tap((res) => this.hasTemplates.emit(!!res.length)));
    }

    ngOnInit() {
        if (this.selected) {
            this.template.patchValue(this.selected);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selected'] &&
            changes['selected'].currentValue === null &&
            changes['selected'].currentValue !== changes['selected'].previousValue
        ) {
            this.template.patchValue(null);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
