<div (click)="toggleSignatoryDetails(recipient)"
     class="signatory form-static mr-2 d-flex">
  <div [ngClass]="{ 'text-muted': !recipient.name }"
       class="pr-2 flex-fill">
      {{ recipient.name ? recipient.name : ('signrequest.choose_contact' | translate) + '...' }}
  </div>

  <div class="d-flex">
    <app-auth-methods-indicator [enabled]="enabledAuthMethods"
                                [signatory]="recipient"
                                class="float-right m2">
    </app-auth-methods-indicator>

    <div class="btn btn-link py-0 px-3">
      <em [ngClass]="selected ? 'fa-caret-right' : 'fa-caret-left'" class="fas fa-fw"></em>
    </div>
  </div>
</div>
