import { ModuleWithProviders, NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OnlyNumbersDirective } from './directives/only-numbers/only-numbers.directive';
import { AuthMethodsComponent } from './components/auth-methods/auth-methods.component';
import { AuthMethodsIndicatorComponent } from './components/auth-methods-indicator/auth-methods-indicator.component';
import { SortAuthMethodsPipe } from './pipes/sort-auth-methods/sort-auth-methods.pipe';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { ChartComponent } from './components/chart/chart.component';
import { EmailInputComponent } from './components/email-input/email-input.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { DataListComponent } from './components/data-list/data-list.component';
import { SafeHTMLPipe } from './pipes/safe-html/safe-html.pipe';
import { FormInputs } from './components/form';
import { AccessControlDirective } from './directives/access-control/access-control.directive';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { PhoneNumberValidatorDirective } from './directives/phone-number/phone-number-validator.directive';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { ApiKeySelectorComponent } from './components/api-key-selector/api-key-selector.component';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FileUploadModule } from 'ng2-file-upload';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MomentModule } from 'ngx-moment';
import { FileSaverModule } from 'ngx-filesaver';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {NgbCarouselModule, NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { HeaderComponent } from './components/header/header.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { InfoComponent } from './components/info/info.component';
import { EmailstateComponent } from './components/emailstate/emailstate.component';
import { AddContactComponent } from './components/add-contact/add-contact.component';
import { SrComponents } from './components/signrequest';
import { LanguageInputComponent } from './components/language-input/language-input.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { EditSignatoryComponent } from './components/edit-signatory/edit-signatory.component';
import { TemplateSelectorComponent } from './components/template-selector/template-selector.component';
import { RequestControlsComponent } from './components/request-controls/request-controls.component';
import { PdfPreviewComponent } from './components/pdf-preview/pdf-preview.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import { PdfPreviewImageComponent } from './components/pdf-preview-image/pdf-preview-image.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { HelpContentComponent } from './components/help-content/help-content.component';
import { HelpModalComponent } from './components/help-content/modals/help-modal/help-modal.component';
import { FormInputNumberComponent } from './components/form/form-input-number/form-input-number.component';

const Directives = [
    OnlyNumbersDirective,
    AccessControlDirective,
    PhoneNumberValidatorDirective
];

const Components = [
    FormInputs,
    SrComponents,
    AuthMethodsComponent,
    AuthMethodsIndicatorComponent,
    SearchInputComponent,
    ChartComponent,
    PageHeaderComponent,
    DataListComponent,
    FileUploadComponent,
    PhoneNumberInputComponent,
    EmailInputComponent,
    ApiKeySelectorComponent,
    LanguageSwitchComponent,
    ModalContentComponent,
    HeaderComponent,
    OnboardingComponent,
    InfoComponent,
    EmailstateComponent,
    AddContactComponent,
    LanguageInputComponent
];

const Pipes = [
    SortAuthMethodsPipe,
    TruncatePipe,
    SafeHTMLPipe,
    FilterPipe
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        TooltipModule.forRoot(),
        NgxDatatableModule,
        FileUploadModule,
        ModalModule.forRoot(),
        MomentModule,
        ClipboardModule,
        FileSaverModule,
        NgbTypeaheadModule,
        BsDropdownModule.forRoot(),
        NgbCarouselModule,
        BsDatepickerModule.forRoot(),
        NgxChartsModule,
        NgbModule,
        NgxExtendedPdfViewerModule,
        NgOptimizedImage,
        DragDropModule,
        // AccordionModule.forRoot(),
        // AlertModule.forRoot(),
        // ButtonsModule.forRoot(),
        // CarouselModule.forRoot(),
        // CollapseModule.forRoot(),
        // PaginationModule.forRoot(),
        // ProgressbarModule.forRoot(),
        // RatingModule.forRoot(),
        // TabsModule.forRoot(),
        // TimepickerModule.forRoot(),
        // PopoverModule.forRoot(),
        // TypeaheadModule.forRoot(),
        // ToasterModule,
    ],
    declarations: [
        Components,
        Directives,
        Pipes,
        EditSignatoryComponent,
        TemplateSelectorComponent,
        RequestControlsComponent,
        PdfPreviewComponent,
        PdfPreviewImageComponent,
        HelpContentComponent,
        HelpModalComponent,
        FormInputNumberComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        TooltipModule,
        NgxDatatableModule,
        FileUploadModule,
        ModalModule,
        MomentModule,
        ClipboardModule,
        BsDropdownModule,
        BsDatepickerModule,
        // AccordionModule,
        // AlertModule,
        // ButtonsModule,
        // CarouselModule,
        // CollapseModule,
        // PaginationModule,
        // ProgressbarModule,
        // RatingModule,
        // TabsModule,
        // TimepickerModule,
        // PopoverModule,
        // TypeaheadModule,
        // ToasterModule,
        Components,
        Directives,
        Pipes,
        FilterPipe,
        TemplateSelectorComponent,
        RequestControlsComponent,
        PdfPreviewImageComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
