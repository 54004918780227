import { Injectable } from '@angular/core';
import { HateoasResourceOperation } from '@lagoshny/ngx-hateoas-client';
import { Settings } from '@shared/models/settings/settings';
import { RoleAuthorization } from '@shared/models/settings/role-authorisation';
import {ErrorDisplayService} from '@services/error-display/error-display.service';
import {TranslateService} from '@ngx-translate/core';
import {EnabledAuthMethod} from '@shared/models/settings/enabled-auth-method';

@Injectable({providedIn: 'root'})
export class SettingsService extends HateoasResourceOperation<Settings> {

    constructor(
        private readonly errorDisplayService: ErrorDisplayService,
        private readonly translate: TranslateService
    ) {
        super(Settings);
    }

    get roleAuthorizations(): Promise<RoleAuthorization[]> {
       return this.loadSettings().then((settings) => settings.roleAuthorizations);
    }

    private _settings!: Settings;

    get settings(): Settings {
        return this._settings;
    }

    set settings(settings: Settings) {
        settings.enabledAuthenticationMethods = settings.enabledAuthenticationMethods.filter((am: EnabledAuthMethod, index: number) => {
            // Rename previously stored document template methods if needed
            if (am.authenticationMethod.toString() === 'document_template') {
                am.authenticationMethod = 'documenttemplate';
            }
            // Remove duplicate authentication methods
            return settings.enabledAuthenticationMethods.findIndex(
                (other: EnabledAuthMethod) => am.authenticationMethod === other.authenticationMethod && am.dmtService === other.dmtService
            ) === index;
        });
        this._settings = settings;
    }

    loadSettings(): Promise<Settings> {
        return this.getCollection()
            .toPromise()
            .then(res => {
                this.settings = res.resources[0] || null;
                return this.settings;
            }).catch((error) => {
                this.errorDisplayService.displayError(this.translate.instant('error.server_unavailable.title'), error.message);
                return error;
            });
    }
}
