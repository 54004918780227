export const environment = {
    production: true,
    debug: true,
    dtmConfigUrl: 'https://dtmserver.test.dtm.zynyo.com/server/dtmconfig',
    apiBaseUrl: 'https://sandbox.zynyo.com/api/rest/v4/',
    springAppUrl: 'http://dtmserver.test.dtm.zynyo.com/server',
    hostUrl: 'http://zynyo.test.dtm.zynyo.com',
    keycloakConfig: {
        url: 'https://keycloak.kubernetes.zynyo.com',
        realm: 'Zynyo',
        clientId: 'zynyostartwebclient',
        clientUuid: 'a12b6612-3e61-4524-a868-17eb6d76e184'
    },
    manageUsers: true,
    uploadFileSizeLimit: null,
    helpheroAppId: ''
};
