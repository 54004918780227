import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';
import { Locale } from '@shared/models/signrequest/sign.signatory';

@HateoasResource('contacts')
export class Contact extends Resource {
    id?: number;
    name!: string;
    email!: string;
    phone!: string;
    source?: string;
    department?: string;
    privacyProtected?: boolean;
    replacements: Contact[] = [];
    authenticationMethods: string[] = [];
    locale: Locale = Locale.NL;
}
