

<div class="mb-3">
  <div class="mb-3">
    <h4>{{ title | translate }} &nbsp;</h4>
    <p class="small text-muted">{{ description | translate  }} &nbsp;</p>
  </div>
</div>

<div class="p-3 border rounded border-primary">
  <div [cdkDropListData]="recipientsList"
       (cdkDropListDropped)="drop($event)"
       class="free-signatory-list colored-border position-initial correct-left"
       cdkDropList>
    <div *ngFor="let signatory of recipientsList; let i = index"
         class="signatory-container d-flex mb-3"
         cdkDrag>
          <app-sr-recipient (selectedRecipient)="toggleSignatoryDetails($event)"
                            [enabledAuthMethods]="enabledAuthMethods"
                            [recipient]="signatory"
                            [selected]="signatory === selectedSignatory"
                            class="flex-fill">
          </app-sr-recipient>
      <div>
        <button (click)="removeSignatory(signatory)"
                [disabled]="roleType !== 'CC' && recipientsList.length <= minLength"
                class="btn btn-outline-danger h-100"><em class="fas fa-trash-alt fa-fw"></em>
        </button>
      </div>
    </div>
  </div>

  <button (click)="addSignatory(true)"
          class="btn btn-outline-primary btn-block btn-dashed font-weight-bold">
    <em class="fas fa-plus pr-2"></em> {{ addBtn | translate }}
  </button>
</div>
