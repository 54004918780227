import { Injectable} from '@angular/core';
import { Contact } from '@shared/models/contact/contact';
import {HateoasResourceOperation } from '@lagoshny/ngx-hateoas-client';

@Injectable({
    providedIn: 'root'
})
export class ContactsService extends HateoasResourceOperation<Contact> {
    constructor() {
        super(Contact);
    }

    public createContact(contact: Contact) {
        contact.source = 'START';
        return this.createResource({body: contact});
    }

    public findAll() {
        return this.getCollection();
    }

    public findByDepartment(department: string) {
        department = department.replace('&', '%26');
        return this.searchCollection('findByDepartmentIgnoreCase?department=' + department);
    }

    public findByEmail(email: string) {
        return this.searchCollection('findByEmailIgnoreCase?email=' + email);
    }


}
