import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Contact } from '@shared/models/contact/contact';
import { ContactsService } from '@services/contacts/contacts.service';
import { TranslateService } from '@ngx-translate/core';
import { EnabledAuthMethod } from '@shared/models/settings/enabled-auth-method';
import { SettingsService } from '@services/settings/settings.service';
import { AuthMethod } from '@shared/models/settings/auth-method';
import { TranslatorService } from '@services/translator/translator.service';
import { FormInput } from '@shared/models/form-input';
import { UserService } from '@services/user/user.service';
import { phoneNumberValidator } from '@utils/phone-number.validator';
import { Locale } from '@shared/models/signrequest/sign.signatory';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-add-contact',
    templateUrl: './add-contact.component.html',
    styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit, OnDestroy {

    public static SUBMIT_SERVICE = 1;
    public static SUBMIT_LOCAL = 2;

    mode: number = AddContactComponent.SUBMIT_SERVICE;

    form: UntypedFormGroup;

    submitting = false;
    contact!: Contact;
    newContact: boolean;
    addContactMsg: string;
    readonly availableMethods: EnabledAuthMethod[] = [];
    disabledAuthMethods: AuthMethod[] = [];
    readonly languageField: FormInput = {
        type: 'select',
        control: 'locale',
        label: 'contacts.language',
        options: []
    };
    private readonly singleAm = false;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly settingsService: SettingsService,
        private readonly translate: TranslateService,
        private readonly bsModalRef: BsModalRef,
        private readonly fb: UntypedFormBuilder,
        private readonly contactService: ContactsService,
        private readonly userService: UserService,
        private readonly translator: TranslatorService,
    ) {
        this.availableMethods = this.settingsService.settings.enabledAuthenticationMethods
            .filter(am => !am.authenticationMethod.endsWith('_preview'));


        this.form = fb.group({
            id: [],
            email: ['', [Validators.email, Validators.required]],
            name: ['', Validators.required],
            phone: ['', phoneNumberValidator()],
            privacyProtected: [],
            department: [],
            saveToList: [],
            authenticationMethods: [],
            locale: []
        });

        this.languageField.options = this.translator.availableLanguagesOptions;

        this.addContactMsg = this.translate.instant('contacts.new_contact');
        this.newContact = true;
        this.disabledAuthMethods = ['smstan'];

        const department = this.userService.department;
        if (department) {
            this.form.get('department')?.patchValue(department);
        }
        this.form.get('locale')?.patchValue(Locale.NL);
    }

    ngOnInit() {
        this.contact = new Contact();

        this.subscription.add(this.form.get('phone')?.valueChanges.subscribe((res) => {
            const smstanIndex = this.disabledAuthMethods.indexOf('smstan');
            const index = this.contact.authenticationMethods.indexOf('smstan');
            const availMouse = this.availableMethods.find(value => value.authenticationMethod === 'mouse');
            const availSMS = this.availableMethods.find(value => value.authenticationMethod === 'smstan');

            if (res?.length) {
                this.disabledAuthMethods.splice(smstanIndex, 1);
                if (index === -1 && this.contact.id === undefined) {
                    if (availSMS && availSMS.defaultEnabled) {
                        this.contact.authenticationMethods.push('smstan');
                    }
                    const mouseIndex = this.contact.authenticationMethods.indexOf('mouse');
                    if (mouseIndex !== -1) {
                        if (availMouse && availMouse.defaultEnabled) {
                            this.contact.authenticationMethods.splice(mouseIndex, 1);
                        }
                    }
                }
            } else {
                this.disabledAuthMethods.push('smstan');
                if (index !== -1 && this.contact.id === undefined) {
                    if (availMouse && availMouse.defaultEnabled) {
                        this.contact.authenticationMethods.splice(index, 1);
                        this.contact.authenticationMethods.push('mouse');
                    }
                }
            }
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setSubmitMode(mode: number) {
        this.mode = mode;
    }

    setContact(contact: Contact, newContact = false) {
        this.contact = contact;
        if (!newContact) {
            this.addContactMsg = this.translate.instant('contacts.edit_contact');
        }
        this.form.patchValue(this.contact);
    }

    onSubmit(form: UntypedFormGroup) {
        const formFields: any = _.omitBy(form.value, _.isNil);
        this.contact = {...this.contact, ...formFields};

        if (this.mode === AddContactComponent.SUBMIT_SERVICE || form.controls['saveToList'].value) {

            if (this.newContact) {
                this.subscription.add(this.contactService.createContact(this.contact).subscribe(
                    data => {
                        this.contact = <Contact>data;
                        this.bsModalRef.hide();
                    }, () => {
                        console.log('errorCreate');
                    }
                ));
            } else {
                this.subscription.add(this.contactService.updateResource(this.contact).subscribe(
                    data => {
                        this.contact = <Contact>data;
                        this.bsModalRef.hide();
                    }, () => {
                        console.log('errorUpdate');
                    }
                ));
            }
        } else if (this.mode === AddContactComponent.SUBMIT_LOCAL) {
            this.bsModalRef.hide();
        }
    }

    toggleAuthMethod(authMethod: AuthMethod): void {
        if (this.singleAm) {
            this.contact.authenticationMethods = [authMethod];
        } else {
            const exist = this.contact.authenticationMethods.indexOf(authMethod);

            if (exist !== -1) {
                this.contact.authenticationMethods.splice(exist, 1);
            } else {
                this.contact.authenticationMethods.push(authMethod);
            }
        }
    }

    cancel() {
        this.bsModalRef.hide();
    }

    get emailControl() {
        return this.form.get('email');
    }

    get phoneControl() {
        return this.form.get('phone');
    }

    patchEmail(email: string) {
        this.emailControl?.patchValue(email);
    }

    emailErrors(errors: ValidationErrors) {
        if (errors && this.emailControl?.touched) {
            this.emailControl?.setErrors(errors);
        }
    }

    updatePhone(phone: string) {
        this.phoneControl?.patchValue(phone);
    }

    phoneErrors(errors: ValidationErrors) {
        if (errors && this.phoneControl?.touched) {
            this.phoneControl?.setErrors(errors);
        }
    }
}
