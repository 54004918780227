<div class="relative" dropdown>
  <button aria-controls="languageSwitch"
          class="btn btn-outline-primary"
          dropdownToggle
          id="languageSwitch"
          tooltip="{{ selectedLanguage?.text }}"
          type="button">
    <span class="fa fa-fw fa-globe"></span>
  </button>
  <ul *dropdownMenu
      aria-labelledby="languageSwitch"
      class="dropdown-menu dropdown-menu-right"
      role="menu">
    <ng-container *ngFor="let language of availableLanguages">
      <li role="menuitem"><button (click)="setLanguage(language)" [class.active]="selectedLanguage.code === language.code" class="dropdown-item">{{ language.text }}</button></li>
    </ng-container>
  </ul>
</div>
