import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from '@services/authorization/authorization.service';

@Directive({
    selector: '[accessControl]'
})
export class AccessControlDirective implements OnInit {
    @Input() accessControl?: string;

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly authorizationService: AuthorizationService
    ) {
    }

    ngOnInit() {
        if (this.isEnabled()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    private isEnabled(): boolean {
        if (this.authorizationService.isSysOp) {
            return true;
        }
        if (!this.accessControl) {
            return false;
        }
        return this.authorizationService.actionAllowed(this.accessControl);
    }
}
