import {HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';
import { CertificateLabel } from './certificate.label';
import { EnabledAuthMethod } from './enabled-auth-method';
import { RoleAuthorization } from './role-authorisation';


@HateoasResource('settings')
export class Settings extends Resource {
    id!: number;
    contactsImportFile?: string;
    separateAuditlog?: boolean | null;
    smtpServer?: string;
    smtpPort?: number;
    starttls?: boolean;
    smtpUser?: string;
    smtpPassword?: string;
    emailfrom?: string;
    departmentFilter?: boolean;
    departmentFilterWorkflows?: boolean;
    departmentFilterContacts?: boolean;
    apiAccountsSeparator?: boolean;
    signrequestCheck?: boolean;
    certificateLabelOption?: string;
    certificateLabel?: CertificateLabel = new CertificateLabel();
    importSSOContacts?: string;
    addSubmitterAsCC?: boolean;
    enabledAuthenticationMethods: EnabledAuthMethod[] = [];
    stampImage?: string;
    expireAfter?: number;
    edeliveryExpireAfter?: number;
    sendDate!: number;
    signatoryAcceptance?: boolean;
    roleAuthorizations: RoleAuthorization[] = [];
    roles: string[] | undefined;
    apikey?: string;

}
