import { Injectable } from '@angular/core';
import {HateoasResourceOperation} from '@lagoshny/ngx-hateoas-client';
import { SearchQuery } from '@shared/models/searchquery/search.query';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SearchQueryService extends HateoasResourceOperation<SearchQuery> {
    private searchQueries$ = new BehaviorSubject<SearchQuery[]>([]);

    constructor() {
        super(SearchQuery);
    }

    createSearchQuery(searchQuery: SearchQuery) {
        console.log('createSearchQuery', searchQuery);
        return this.createResource({ body: searchQuery} );
    }

    deleteSearchQuery(searchQuery: SearchQuery) {
        return this.deleteResource(searchQuery);
    }

    findAll() {
        this.getCollection().subscribe(res => this.searchQueries$.next(res.resources));
    }

    findByType(type: string): Observable<SearchQuery[]> {
        return this.searchQueries$.pipe(map((query: SearchQuery[]) => query.filter(q => q.type === type)));
    }
}
