import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ZynyoApiAccount } from '../../models/api-account/zynyo.api.account';
import { ApiAccountsService } from '@services/api-account/api-accounts.service';

@Component({
    selector: 'app-api-key-selector',
    templateUrl: './api-key-selector.component.html',
    styles: []
})
export class ApiKeySelectorComponent {
    @Input() departmentFilter?: string;
    @Output() update: EventEmitter<string | null> = new EventEmitter<string | null>();

    selectedApiKey!: string;
    apiAccounts: ZynyoApiAccount[] = [];
    noApiAccount = false;

    constructor(
        private readonly service: ApiAccountsService
    ) {
        this.selectedApiKey = this.service.ApiKey;
        this.apiAccounts = this.service.apiAccounts;

        this.setApiAccounts();
    }

    updateApiKey() {
        this.service.ApiKey = this.selectedApiKey;
        this.update.emit(this.selectedApiKey);
    }

    private setApiAccounts(): void {
        if (!this.apiAccounts) {
            this.selectedApiKey = '';
            return;
        }
        this.noApiAccount = (!this.apiAccounts.length);
        if (!this.apiAccounts.length) {
            this.selectedApiKey = '';
        } else {
            if (this.departmentFilter && this.apiAccounts.length > 1) {
                const department = this.apiAccounts.find(value => {
                    return value.name.trim() === this.departmentFilter?.trim();
                });

                this.selectedApiKey = department?.apiKey ?? '';
            }
            if (!this.selectedApiKey) {
                this.selectedApiKey = this.apiAccounts[0].apiKey;
            }

            this.updateApiKey();
        }
    }
}
