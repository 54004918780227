<nav #navBar
     [ngClass]="navCollapsed ? 'collapsed' : 'expanded' "
     class="side-navbar"
     id="navbar"
     role="navigation">

    <div (click)="toggleSidebar()" class="navbar-logo"><img [src]="'assets/img/' + (navCollapsed ? 'zynyoicon':'zynyo-logo') +'.svg'" alt="Zynyo Logo"></div>
    <div id="menu">
        <ul class="nav mt-3 mt-lg-4">
            <li *ngFor="let item of menuItems" [ngClass]="{'nav-heading': item.heading}" [routerLinkActive]="['active']">
                <ng-container *ngIf="item.heading; else menuItem">
                    <span *ngIf="item.heading">{{ (item.text | translate) || item.text }}</span>
                </ng-container>

                <ng-template #menuItem>
                    <a [routerLink]="item.link"
                       [title]="(item.text | translate) || item.text"
                       [tooltip]="navCollapsed ? (item.text | translate) || item.text : null">
                        <em *ngIf="item.icon" class="{{item.icon}}"></em>
                        <span>{{(item.text | translate) || item.text}} </span>
                    </a>
                </ng-template>
            </li>
        </ul>
    </div>
    <div class="p-3" id="user-menu">
        <a routerLink="/profile"><em class="fas fa-user fa-fw"></em> <span *ngIf="!this.navCollapsed">{{ username }}</span></a>
        <a href="https://zynyo.atlassian.net/servicedesk/" target="_blank"><em class="fas fa-question fa-fw"></em> <span *ngIf="!this.navCollapsed">{{ 'header.faq' | translate }}</span></a>
        <button (click)="logout()" class="mt-2 btn btn-logout"><em class="fas fa-sign-out-alt fa-fw"></em> <span *ngIf="!this.navCollapsed">{{ 'header.logout' | translate }}</span></button>
    </div>
</nav>
