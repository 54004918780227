import {Component, Input} from '@angular/core';
import {HelpService} from '@services/help/help.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {HelpModalComponent} from '@shared/components/help-content/modals/help-modal/help-modal.component';
import {Help} from '@shared/models/help/help';
import {ModalContentComponent} from '@shared/components/modal-content/modal-content.component';

@Component({
    selector: 'app-help-content',
    templateUrl: './help-content.component.html',
    styleUrls: ['./help-content.component.scss']
})
export class HelpContentComponent {

    modalRef = new BsModalRef<any>();
    @Input() key!: string;

    constructor(
        private readonly helpService: HelpService,
        private readonly modalService: BsModalService
    ) {
    }

    onClick() {
        this.helpService.getHelpByKey(this.key).then((help: Help) => {
            if (help.content !== undefined) {
                const initialState = {
                    help: help
                };
                this.modalRef = this.modalService.show(HelpModalComponent, {initialState});
                this.modalRef.setClass('modal-lg')
            } else {
                const initialState = {
                    title: 'help.error.title',
                    message: 'help.error.content',
                    cancelTxt: 'signrequest.close',
                };
                this.modalRef = this.modalService.show(ModalContentComponent, {initialState});
            }
        });
    }
}
