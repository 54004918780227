import { DataService } from '@services/data/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentTemplate } from '@shared/models/documenttemplate/document.template';
import { Observable } from 'rxjs';
import { ConfigService } from '@services/config/config.service';

@Injectable({
    providedIn: 'root'
})
export class DocumenttemplatesService extends DataService {

    constructor(
        protected readonly http: HttpClient,
        protected readonly config: ConfigService
    ) {
        super('documenttemplates', http, config);
    }

    getDocumentTemplates(): Observable<DocumentTemplate[]> {
        return this.getAll<DocumentTemplate[]>();
    }
}

