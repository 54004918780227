<div class="modal-header">
      <h4 class="modal-title float-left">{{ title | translate }}</h4>
</div>

<div class="modal-body" [innerHtml]="message | translate"></div>

<div class="modal-footer">

    <ng-container *ngIf="pending; else showActions">
        <div></div>
        <div class="ball-clip-rotate"><div></div></div>
    </ng-container>

    <ng-template #showActions>
        <div>
          <button type="button" *ngIf="closeBtn" class="btn text-secondary btn-outline-secondary" (click)="closeAction()">{{ closeText | translate}}</button>
        </div>
        <div>
          <button type="button" *ngIf="cancelBtn" class="btn ml-3" [ngClass]="cancelClass" (click)="cancelAction()">{{ cancelTxt | translate}}</button>
          <button type="button" *ngIf="proceedBtn" class="btn ml-3" [ngClass]="proceedClass" (click)="proceedAction()">{{ proceedTxt | translate}}</button>
        </div>
    </ng-template>
</div>
