import { APP_INITIALIZER, InjectionToken, NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { initializer } from '@utils/app-init';
import { AppAuthGuard } from '@security/app-auth.guard';
import { registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslatorService } from '@services/translator/translator.service';
import { AppRoutingModule } from './app-routing.module';
import {MODAL_CONFIG_DEFAULT_OVERRIDE} from 'ngx-bootstrap/modal';
import { NgxHateoasClientConfigurationService, NgxHateoasClientModule } from '@lagoshny/ngx-hateoas-client';
import { Settings } from '@shared/models/settings/settings';
import { EnabledAuthMethod } from '@shared/models/settings/enabled-auth-method';
import { RoleAuthorization } from '@shared/models/settings/role-authorisation';
import { ConfigService } from '@services/config/config.service';
import { ConsoleLogging } from '@utils/console-logging';
import {ErrorDisplayService} from '@services/error-display/error-display.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const LOCATION_TOKEN = new InjectionToken<Location>('Window location object');

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        KeycloakAngularModule,
        HttpClientModule,
        NgxHateoasClientModule.forRoot(),
        RouterModule.forRoot([], {}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        SharedModule.forRoot(),
        AppRoutingModule
    ],
    providers: [
        {provide: LOCATION_TOKEN, useValue: window.location},
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService, TranslatorService, ConfigService, ErrorDisplayService, LOCATION_TOKEN]
        },
        {
            provide: MODAL_CONFIG_DEFAULT_OVERRIDE,
            useValue: {keyboard: true, ignoreBackdropClick: true}
        },
        AppAuthGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule extends ConsoleLogging {
    constructor(
        @Optional() @SkipSelf() sharedModule: SharedModule,
        hateoasConfig: NgxHateoasClientConfigurationService,
        configService: ConfigService
    ) {
        registerLocaleData(localeNl, 'nl');

        hateoasConfig.configure({
            http: {
                rootUrl: 'https://localhost'
            },
            useTypes: {
                resources: [Settings],
                embeddedResources: [EnabledAuthMethod, RoleAuthorization]
            }
        });

        super(configService);
    }
}

